<template>
  <CFlex direction="column" id="infobox" :class="{ expanded: isExpanded }">
    <template v-if="isLoading">
      <CBox p="1">
        <c-spinner color="white" />
      </CBox>
    </template>
    <template v-else>
      <CFlex
        class="infobox-toolbar"
        direction="horizontal"
        justify="space-between"
      >
        <c-heading as="h5" size="sm">{{ description.type }}</c-heading>
        <CFlex dir="row">
          <c-icon-button
            size="xs"
            variant-color="vue"
            variant="outline"
            :icon="isExpanded ? 'shrink' : 'expand'"
            aria-label="Expand"
            @click="
              () => {
                isExpanded ? shrinkInfoBox() : expandInfoBox();
              }
            "
            mr="1"
          />
          <c-close-button size="sm" @click="$emit('close')" />
        </CFlex>
      </CFlex>
      <vue-scroll
        :ops="{
          scrollPanel: {
            scrollingX: false,
          },
        }"
      >
        <CFlex :direction="isExpanded ? 'row' : 'column'" class="infobox-content">
          <CBox flex="1">
            <p>
              Date/Time:
              <c-button
                v-if="
                  !$store.state.SightFilters.filters.some((f) => f.attr == 'day')
                "
                variant-color="white"
                variant="link"
                size="sm"
                @click="
                  onSightSelected(
                    `day`,
                    $moment
                      .parseZone(description.dateTime)
                      .utc()
                      .startOf('day')
                      .format('YYYY-MM-DDTHH:mm:ssZ')
                  )
                "
              >
                {{ description.dateTime | moment("dddd Do") }}
              </c-button>
              <span v-else>{{ description.dateTime | moment("dddd Do") }}</span
              >,
              <c-button
                v-if="
                  !$store.state.SightFilters.filters.some(
                    (f) => f.attr == 'month'
                  )
                "
                variant-color="white"
                variant="link"
                size="sm"
                @click="
                  onSightSelected(
                    `month`,
                    $moment
                      .parseZone(description.dateTime)
                      .utc()
                      .startOf('month')
                      .format('YYYY-MM-DDTHH:mm:ssZ')
                  )
                "
              >
                {{ description.dateTime | moment("MMMM") }}
              </c-button>
              <span v-else>{{ description.dateTime | moment("MMMM") }}</span
              >,
              <c-button
                v-if="
                  !$store.state.SightFilters.filters.some((f) => f.attr == 'year')
                "
                variant-color="white"
                variant="link"
                size="sm"
                @click="
                  onSightSelected(
                    `year`,
                    $moment
                      .parseZone(description.dateTime)
                      .utc()
                      .startOf('year')
                      .format('YYYY-MM-DDTHH:mm:ssZ')
                  )
                "
              >
                {{ description.dateTime | moment("YYYY") }}
              </c-button>
              <span v-else>{{ description.dateTime | moment("YYYY") }}</span
              >,
              <c-button
                v-if="
                  !$store.state.SightFilters.filters.some((f) => f.attr == 'time')
                "
                variant-color="white"
                variant="link"
                size="sm"
                @click="
                  onSightSelected(
                    `time`,
                    $moment
                      .parseZone(description.dateTime)
                      .utc()
                      .startOf('second')
                      .format('YYYY-MM-DDTHH:mm:ssZ')
                  )
                "
              >
                {{ description.dateTime | moment("h:mm a") }}
              </c-button>
              <span v-else>{{ description.dateTime | moment("h:mm a") }}</span>
            </p>
            <p>Latitude: {{ description.latitude }}</p>
            <p>Longitude: {{ description.longitude }}</p>
            <p v-if="description.deviceId">
              DeviceID: {{ description.deviceId }}
            </p>
            <p v-if="description.capturer">
              Capturer: {{ description.capturer }}
            </p>
            <c-heading v-if="description.sighting" as="h6" size="sm" mt="1"
              >Description</c-heading
            >
            <div v-if="description.sighting" class="info-section">
              <div v-for="(key, i) in Object.keys(sortedSighting)" :key="i">
                {{ key | formatSightAttr }}:
                <c-button
                  v-if="key.includes('gdmPhoto')"
                  variant-color="white"
                  variant="link"
                  size="sm"
                  @click="onClickImageLink(description.sighting[key])"
                  >[ Photo Link ]</c-button
                >
                <span
                  v-else-if="
                    $store.state.SightFilters.filters.some(
                      (f) =>
                        f.attr == `sighting.${key}` &&
                        f.value == description.sighting[key]
                    )
                  "
                >
                  {{ description.sighting[key] }}
                </span>
                <c-button
                  v-else
                  variant-color="white"
                  variant="link"
                  size="sm"
                  @click="
                    onSightSelected(`sighting.${key}`, description.sighting[key])
                  "
                >
                  {{ description.sighting[key] }}
                </c-button>
              </div>
            </div>
            <c-heading v-if="Array.isArray(description.extras) && description.extras.length" as="h6" size="sm" mt="1"
              >Categories</c-heading
            >
            <div v-for="(extra, i) in description.extras" :key="i">
              <div class="info-section">
                <entity-object
                  :item="extra"
                  parent="category"
                  :skips="['_key', '_id', '_rev', 'type', 'gdmAsset']"
                />
              </div>
            </div>
          </CBox>
          <CBox flex="1">
            <div v-if="Array.isArray(images) && images.length" id="sight-images">
              <c-heading
                v-if="description.sighting"
                as="h6"
                size="sm"
                mt="1"
                mb="2"
                >Images</c-heading
              >
              <Photoswipe v-if="!isExpanded">
                <swiper ref="mySwiper" :options="swiperOptions">
                  <swiper-slide
                    v-for="(image, i) in images"
                    :key="`${description.latitude}${description.longitude}${i}`"
                  >
                    <c-aspect-ratio-box max-w="400px" :ratio="19 / 6">
                      <c-image
                        :src="getImageUrl(image)"
                        object-fit="cover"
                        v-pswp="getImageUrl(image)"
                      />
                    </c-aspect-ratio-box>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </Photoswipe>
              <Photoswipe v-else>
                <CBox
                  v-for="(image, i) in images"
                  mb="2"
                  :key="`${description.latitude}${description.longitude}${i}`"
                >
                  <c-aspect-ratio-box :ratio="4 / 3">
                    <c-image
                      :src="getImageUrl(image)"
                      object-fit="cover"
                      rounded="10px"
                      v-pswp="getImageUrl(image)"
                    />
                  </c-aspect-ratio-box>
                </CBox>
              </Photoswipe>
            </div>
          </CBox>
        </CFlex>
      </vue-scroll>
    </template>
  </CFlex>
</template>

<script>
import { Photoswipe } from "vue-pswipe";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import {
  CBox,
  CFlex,
  CHeading,
  CButton,
  CIconButton,
  CCloseButton,
  CImage,
  CAspectRatioBox,
  CSpinner
} from "@chakra-ui/vue";
import EntityObject from "./EntityObject.vue";
const sortOrder = new Set(Object.keys({
  Country: "",
  Reserve: "",
  Lodge: "",
  Ranger: "",
  Latitude: "",
  Longitude: "",
  Day: "",
  Month: "",
  Year: "",
  CaptureDate: "",
  CaptureTime: "",
  Species: "",
  LodgeIndivName: "",
  KnownIndividual: "",
  Sex: "",
  Age: "",
  Cubs: "",
  "No.Cubs": "",
  Kill: "",
  KillType: "",
  PreySpecies: "",
  PreySex: "",
  PreyAge: "",
  Hoisted: "",
  ScavengersPresent: "",
  ScavengerSpecies: "",
  KillStolen: "",
  Mating: "",
  MatingWith: "",
  MatingSex: "",
  MatingAge: "",
  LastSighting: "",
  Notes: "",
}));

export default {
  props: {
    description: Object,
    isLoading: {
      default: false,
      type: Boolean
    }
  },
  components: {
    EntityObject,
    CBox,
    CFlex,
    CHeading,
    CButton,
    CIconButton,
    CCloseButton,
    CImage,
    CAspectRatioBox,
    Photoswipe,
    Swiper,
    SwiperSlide,
    CSpinner
  },
  filters: {
    formatSightAttr(value) {
      return value.replace("gdm", "").replace(/((?<![.| ])[A-Z])/g, " $1");
    },
  },
  data() {
    return {
      isExpanded: false,
      sightImages: [],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    images() {
      if (!this.description.sighting) return [];
      const sighting = this.description.sighting;
      const imageKeys = Object.keys(sighting).filter((key) =>
        key.includes("gdmPhoto")
      );
      return imageKeys.map((k) => sighting[k]);
    },
    sortedSighting() {
      if (!this.description.sighting) {
        return {};
      }
      const keys = Object.keys(this.description.sighting);
      for(let key of keys) {
        sortOrder.add(key)
      }
      const sighting = {};
      for(let attr of sortOrder) {
        if(this.description.sighting[attr]) {
          sighting[attr] = this.description.sighting[attr]
        }
      }
      return sighting;
    },
  },
  methods: {
    onSightSelected(attr, value) {
      this.$store.dispatch("addSightFilter", { attr, value });
    },
    onClickImageLink(cImage) {
      this.$Pswp.open({
        items: this.images.map((image) => {
          return {
            src: `${process.env.VUE_APP_IMAGE_BASE}/${image}`,
          };
        }),
        options: {
          index: this.images.indexOf(cImage),
        },
      });
    },
    expandInfoBox() {
      this.isExpanded = true;
    },
    shrinkInfoBox() {
      this.isExpanded = false;
    },
    getImageUrl(image) {
      return `${process.env.VUE_APP_IMAGE_BASE}/${image}`;
    },
  },
};
</script>

<style lang="scss" scoped>
#infobox {
  position: absolute;
  top: 70px;
  right: 5px;
  width: 350px;
  z-index: 101;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  background: rgba(43, 140, 91, 0.8);
  border-radius: 5px;
  max-height: calc(100vh - 275px);
  box-shadow: -1px 0 10px rgba(0, 0, 0, 0.8);
  transition: all 1.5s ease-in-out;
  transition-property: width, max-height, top, right, left, bottom;
  &.expanded {
    top: 50px;
    right: 12.5vw;
    width: 75vw;
    max-height: calc(100vh - 100px);
    .infobox-toolbar,
    .infobox-content {
      padding: 1rem;
    }
  }
  .infobox-toolbar,
  .infobox-content {
    padding: 5px 8px;
    transition: padding 1.5s ease-in-out;
  }
  .infobox-toolbar {
    border-radius: 5px 5px 0 0;
    background: rgba(43, 140, 91, 1);
  }
}
.info-section {
  margin-left: 1rem;
}
</style>