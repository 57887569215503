<template>
  <div>
    {{attr}}: {{value}}
  </div>
</template>
<script>
export default {
  props: ['attr', 'value'],
  methods: {
    onSightSelected(attr, value) {
      this.$store.dispatch('addSightFilter', {attr, value})
    }
  }
}
</script>