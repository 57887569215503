<template>
  <CBox
    d="flex"
    w="100vw"
    h="100vh"
    flex-dir="column"
    alignItems="center"
    class="container-map"
    :class="{'entity-form-open': isEntityFormShown}"
  >
    <cesium-viewer
      ref="cev"
      :opening-position="[29.5, -33, 31.5, -32]"
      @entity-selected="onEntitySelected"
      @mouse-move="onMouseMove"
      @map-click="onMapClick"
      @pp-click="onPointClick"
      @entityCountUpdated="(count) => {
        entityCount = count
      }"
      @timeline-scrub="() => {
        isPlaying = false
      }"
    />
    <c-box id="main-navigation" d="flex" align-items="center">
      <c-image
        src="/panthera-logo.png"
        w="192px"
        class="nav-logo"
        ml="2"
        objectFit="cover"
        alt="Panthera"
      />
      <c-image
        src="/panthera-logo-text.png"
        w="192px"
        class="nav-logo"
        ml="2"
        objectFit="cover"
        alt="Panthera"
      />
      <c-box mx="auto" d="flex" color="#fff">
        <c-tooltip label="Layers" placement="bottom">
          <c-button
            @click.stop="() => {togglePopWindow('layers')}"
            size="sm"
            rounded="0"
            bg="rgba(30, 106, 68, 0.8)"
            :_hover="{
              background: 'rgba(30, 106, 68, 1)'
            }"
            :_active="{
              background: 'rgba(30, 106, 68, 0.8)'
            }"
            :_focus="{
              outline: 'none',
            }"
          >
            <c-icon name="layers" />
          </c-button>
        </c-tooltip>
        <c-tooltip label="Capture" placement="bottom">
          <c-button
            size="sm"
            rounded="0"
            bg="rgba(30, 106, 68, 0.8)"
            ml="1"
            @click.stop="() => {togglePopWindow('capture')}"
            :_hover="{
              background: 'rgba(30, 106, 68, 1)'
            }"
            :_active="{
              background: 'rgba(30, 106, 68, 0.8)'
            }"
            :_focus="{
              outline: 'none',
            }"
          >
            <c-icon name="capture" />
          </c-button>
        </c-tooltip>
        <c-flex dir="row">
          <c-tooltip label="Search" placement="bottom">
            <c-button
              size="sm"
              rounded="0"
              bg="rgba(30, 106, 68, 0.8)"
              @click.stop="() => {togglePopWindow('search')}"
              :_hover="{
                background: 'rgba(30, 106, 68, 1)'
              }"
              :_active="{
                background: 'rgba(30, 106, 68, 0.8)'
              }"
              :_focus="{
                outline: 'none',
              }"
              ml="1"
              right-icon="chevron-down"
            >
              <c-icon name="search" />
            </c-button>
          </c-tooltip>
          <c-flex as="form" color="#000" @submit.native.prevent="onAnySightSearch">
            <c-input
              v-model="anySight"
              :isDisabled="isRefreshing"
              @focus="() => {
                isSearchFocused = true
                activePopWindow='search'
              }"
              @blur="() => {
                isSearchFocused = false
              }"
              type="text"
              placeholder="Search..."
              size="sm"
            />
          </c-flex>
        </c-flex>
        <c-tooltip label="Data" placement="bottom">
          <c-button
            size="sm"
            rounded="0"
            bg="rgba(30, 106, 68, 0.8)"
            :_hover="{
              background: 'rgba(30, 106, 68, 1)'
            }"
            :_active="{
              background: 'rgba(30, 106, 68, 0.8)'
            }"
            :_focus="{
              outline: 'none',
            }"
            @click.stop="() => {togglePopWindow('data')}"
            ml="1"
          >
            <c-icon name="dataSettings" />
          </c-button>
        </c-tooltip>
        <c-tooltip label="Report" placement="bottom">
          <c-button
            size="sm"
            rounded="0"
            bg="rgba(30, 106, 68, 0.8)"
            @click.stop="togglePopWindow('report')"
            :_hover="{
              background: 'rgba(30, 106, 68, 1)'
            }"
            :_active="{
              background: 'rgba(30, 106, 68, 0.8)'
            }"
            :_focus="{
              outline: 'none',
            }"
            ml="1"
          >
            <c-icon name="report" />
          </c-button>
        </c-tooltip>
        <c-tooltip label="Settings" placement="bottom">
          <c-button
            :_hover="{
              background: 'rgba(30, 106, 68, 1)'
            }"
            :_active="{
              background: 'rgba(30, 106, 68, 0.8)'
            }"
            :_focus="{
              outline: 'none',
            }"
            @click.stop="() => {togglePopWindow('settings')}"
            size="sm"
            rounded="0"
            bg="rgba(30, 106, 68, 0.8)"
            ml="1"
          >
            <c-icon name="gear" />
          </c-button>
        </c-tooltip>
        <c-tooltip label="Refresh" placement="bottom">
          <c-button
            @click="refresh"
            :disabled="isRefreshing"
            size="sm"
            ml="1"
            rounded="0"
            bg="rgba(30, 106, 68, 0.8)"
            :_hover="{
              background: 'rgba(30, 106, 68, 1)'
            }"
            :_active="{
              background: 'rgba(30, 106, 68, 0.8)'
            }"
            :_focus="{
              outline: 'none',
            }"
          >
            <c-icon name="refresh" />
          </c-button>
        </c-tooltip>
      </c-box>
    </c-box>
    <transition-group name="popupwindow" tag="div" class="popwindow-wrapper">
      <!-- layers -->
      <pop-window
        v-if="activePopWindow=='layers'"
        @close="() => {activePopWindow = -1}"
        key="layers">
        <c-tabs
          :default-index="1"
          class="vertical-tabs"
          orientation="vertical"
        >
          <c-tab-list>
            <c-tab>Layers</c-tab>
            <c-tab>Details</c-tab>
          </c-tab-list>
          <c-tab-panels class="tab-panels">
            <c-tab-panel>
              No Layers
            </c-tab-panel>
            <c-tab-panel height="100%">
              <vue-scroll>
                <div
                  v-for="(czml, index) in czmls"
                  :key="index"
                  style="color: #fff; padding: 0 0 0.5rem">
                  <c-checkbox
                    :is-checked="activeCzmls.indexOf(index) > -1"
                    @change="(v, $e) => {onCzmlToggle(index, $e)}"
                  >{{czml[0].name}}</c-checkbox>
                </div>
              </vue-scroll>
            </c-tab-panel>
          </c-tab-panels>
        </c-tabs>
      </pop-window>
      <!-- captures -->
      <pop-window
        v-if="activePopWindow=='capture'"
        @close="() => {activePopWindow = -1}"
        key="capture">
        <vue-scroll>
          <c-box p="4">
            <References @onReferenceSelect="onReferenceSelect"/>
          </c-box>
          <c-box p="4">
            <Asset3dList :models='models' @focus="focus3dAsset" @remove="remove3dAsset" />
          </c-box>
          <c-box p="4">
            <Asset3dInput @asset-added="load3dAssets"/>
          </c-box>
          <c-box p="4">
            <c-heading size="sm">FlyTo Test</c-heading>
            <c-box border-width="1px" border-color="#fff" p="1" mt="1">
              <c-heading size="sm">Destination</c-heading>
              <c-stack is-inline>
                <c-form-control>
                  <c-form-label for="d-lon">Longitude (deg)</c-form-label>
                  <c-input
                    id="d-lon"
                    v-model="flyToTest.desLon"
                    size="sm"
                    color="#000"
                  />
                </c-form-control>
                <c-form-control>
                  <c-form-label for="d-lat">Latitude (deg)</c-form-label>
                  <c-input
                    id="d-lat"
                    v-model="flyToTest.desLat"
                    size="sm"
                    color="#000"
                  />
                </c-form-control>
                <c-form-control>
                  <c-form-label for="d-height">Height (meter)</c-form-label>
                  <c-input
                    id="d-height"
                    v-model="flyToTest.desHeight"
                    size="sm"
                    color="#000"
                  />
                </c-form-control>
              </c-stack>
            </c-box>
            <c-box border-width="1px" border-color="#fff" p="1" mt="1">
              <c-heading size="sm">
                <c-checkbox v-model="flyToTest.useOrientation"/>
                Orientation
              </c-heading>
              <c-stack is-inline align-items="center">
                <c-text>Heading, Pitch, Roll</c-text>
                <c-switch
                  v-model="flyToTest.useDU"
                  font-size="0"
                  :is-disabled="!flyToTest.useOrientation"
                />
                <c-text>Direction, Up</c-text>
              </c-stack>
              <c-box v-if="flyToTest.useDU">
                <c-heading size="sm" mt="1">Direction</c-heading>
                <c-stack is-inline>
                  <c-form-control>
                    <c-form-label for="d-lon">Longitude (deg)</c-form-label>
                    <c-input
                      id="d-lon"
                      v-model="flyToTest.dirLon"
                      size="sm"
                      color="#000"
                    />
                  </c-form-control>
                  <c-form-control>
                    <c-form-label for="d-lat">Latitude (deg)</c-form-label>
                    <c-input
                      id="d-lat"
                      v-model="flyToTest.dirLat"
                      size="sm"
                      color="#000"
                    />
                  </c-form-control>
                  <c-form-control>
                    <c-form-label for="d-height">Height (meter)</c-form-label>
                    <c-input
                      id="d-height"
                      v-model="flyToTest.dirHeight"
                      size="sm"
                      color="#000"
                    />
                  </c-form-control>
                </c-stack>
                <c-heading size="sm" mt="1">Up</c-heading>
                <c-stack is-inline>
                  <c-form-control>
                    <c-form-label for="d-lon">Longitude (deg)</c-form-label>
                    <c-input
                      id="d-lon"
                      v-model="flyToTest.upLon"
                      size="sm"
                      color="#000"
                    />
                  </c-form-control>
                  <c-form-control>
                    <c-form-label for="d-lat">Latitude (deg)</c-form-label>
                    <c-input
                      id="d-lat"
                      v-model="flyToTest.upLat"
                      size="sm"
                      color="#000"
                    />
                  </c-form-control>
                  <c-form-control>
                    <c-form-label for="d-height">Height (meter)</c-form-label>
                    <c-input
                      id="d-height"
                      v-model="flyToTest.upHeight"
                      size="sm"
                      color="#000"
                    />
                  </c-form-control>
                </c-stack>
              </c-box>
              <c-box v-else>
                <c-heading size="sm" mt="1">Heading, Pitch, Roll</c-heading>
                <c-stack is-inline>
                  <c-form-control>
                    <c-form-label for="h-heading">Heading (deg)</c-form-label>
                    <c-input
                      id="h-heading"
                      v-model="flyToTest.heading"
                      size="sm"
                      color="#000"
                    />
                    <c-form-helper-text color="#dfdfdf">
                      Relative to EAST
                    </c-form-helper-text>
                  </c-form-control>
                  <c-form-control>
                    <c-form-label for="h-pitch">Pitch (deg)</c-form-label>
                    <c-input
                      id="h-pitch"
                      v-model="flyToTest.pitch"
                      size="sm"
                      color="#000"
                    />
                    <c-form-helper-text color="#dfdfdf">
                      Relative to NORTH
                    </c-form-helper-text>
                  </c-form-control>
                  <c-form-control>
                    <c-form-label for="h-roll">Roll (deg)</c-form-label>
                    <c-input
                      id="h-roll"
                      v-model="flyToTest.roll"
                      size="sm"
                      color="#000"
                    />
                    <c-form-helper-text color="#dfdfdf">
                      Relative to UP
                    </c-form-helper-text>
                  </c-form-control>
                </c-stack>
              </c-box>
            </c-box>
            <c-form-control>
              <c-form-label for="d-rotation">Orbit Rotation speed (deg)</c-form-label>
              <c-input
                id="d-rotation"
                v-model="flyToTest.speed"
                size="sm"
                color="#000"
              />
              <c-form-helper-text color="#dfdfdf">
                Will be used on orbit rotation
              </c-form-helper-text>
            </c-form-control>
            <c-stack is-inline mt="2">
              <c-button
                size="sm"
                variant-color="vue"
                @click="runFlyTo"
              >Run FlyTo</c-button>
              <c-button
                size="sm"
                variant-color="vue"
                @click="runOrbitAround"
              >Run Orbit around</c-button>
              <c-button
                size="sm"
                variant-color="red"
                @click="runCameraReset"
              >Reset Camera</c-button>
            </c-stack>
          </c-box>
        </vue-scroll>
      </pop-window>
      <!-- search -->
      <pop-window
        v-if="activePopWindow=='search'"
        @close="() => {if(!isSearchFocused) activePopWindow = -1}"
        key="search">
        <c-box d="flex" flex-direction="column" p="4" h="100%">
          <c-checkbox mr="3">Advance search</c-checkbox>
          <c-checkbox>Image search</c-checkbox>
          <c-flex
            id="filter-container"
            direction="column"
            h="100%"
          >
            <c-stack spacing="1" direction="row" my="2">
              <c-form-control>
                <c-form-label color="#fff">
                  From
                </c-form-label>
                <div>
                  <date-picker
                    v-model="dateFrom"
                    :append-to-body="false"
                    @change="(d) => {
                      if(dateTo && (d > dateTo)) {
                        let tomorrow = new Date(d)
                        tomorrow.setDate(tomorrow.getDate()+1)
                        dateTo = tomorrow
                      }
                      refresh()
                    }"
                  />
                </div>
              </c-form-control>
              <c-form-control>
                <c-form-label color="#fff">
                  To
                </c-form-label>
                <div>
                  <date-picker
                    v-model="dateTo"
                    :append-to-body="false"
                    :disabled-date="dateToDisabledDates"
                    @change="refresh"/>
                </div>
              </c-form-control>
            </c-stack>
            <vue-scroll>
              <c-flex
                v-for="(filter, i) in $store.state.SightFilters.anySights"
                direction="row"
                justify="space-between"
                class="filter-item"
                :class="{'filter-item--active': filter.isActive}"
                @click.native="onAnySightToggle(i)"
                :key="i">
                <div>
                  <div class="filter-item__attr">Global</div>
                  <div class="filter-item__value">{{filter.value}}</div>
                </div>
                <c-close-button size="sm" @click.prevent.stop="onAnySightRemove(i)"/>
              </c-flex>
              <c-flex
                v-for="(filter, i) in $store.state.SightFilters.filters"
                direction="row"
                justify="space-between"
                class="filter-item"
                :class="{'filter-item--active': filter.isActive}"
                @click.native="$store.dispatch('toggleActiveSightFilter', i)"
                :key="i">
                <div>
                  <div class="filter-item__attr">{{filter.attr}}</div>
                  <div v-if="filter.attr == 'day'" class="filter-item__value">{{filter.value | moment('MMMM Do, YYYY')}}</div>
                  <div v-else-if="filter.attr == 'month'" class="filter-item__value">{{filter.value | moment('MMMM, YYYY')}}</div>
                  <div v-else-if="filter.attr == 'year'" class="filter-item__value">{{filter.value | moment('YYYY')}}</div>
                  <div v-else-if="filter.attr == 'time'" class="filter-item__value">{{filter.value | moment('dddd, MMMM Do, YYYY, h:mm a')}}</div>
                  <div v-else class="filter-item__value">{{filter.value}}</div>
                </div>
                <c-close-button size="sm" @click.prevent.stop="$store.dispatch('removeSightFilter', i)"/>
              </c-flex>
            </vue-scroll>
          </c-flex>
        </c-box>
      </pop-window>
      <!-- data -->
      <pop-window
        v-if="activePopWindow=='data'"
        @close="() => {activePopWindow = -1}"
        key="data"
      >
        <c-box p="4" display="flex" flex-direction="column" height="100%">
          <vue-scroll>
            <c-box>
              <c-text
                fontSize="md"
                d="flex"
                align-items="center"
                justify-content="space-between"
                mb="2"
              >
                <span>Timelapse</span>
                <c-switch
                  :isChecked="isTimeLapseEnabled"
                  @change="() => {toggleTimeLapse()}"
                  :value="true"
                  size="sm"
                  mr="1"
                />
              </c-text>
              <c-text
                fontSize="md"
                d="flex"
                justify-content="space-between"
                mb="2"
              >
                <span>Data Point Colors</span>
                <c-box>
                  <c-select
                    v-model="activeSightingAttr"
                    placeholder="Select color config"
                    backgroundColor="#55c392"
                    maxWidth="12rem"
                    size="sm"
                  >
                    <option v-for="option in sightingAttrs" :key="option._key" :value="option._key">{{option.title}}</option>
                  </c-select>
                  <c-box>
                    <c-box
                      v-for="attr in Object.keys(colorConfig)"
                      d="flex"
                      align-items="center"
                      justify-content="space-between"
                      :key="attr">
                      <c-text>{{attr}}</c-text>
                      <c-box :backgroundColor="`rgb(${colorConfig[attr].red*255}, ${colorConfig[attr].green*255}, ${colorConfig[attr].blue*255})`"
                        width="2rem"
                        height="2rem"
                      />
                    </c-box>
                  </c-box>
                </c-box>
              </c-text>
              <c-box
                v-if="isTimeLapseEnabled"
                color="#000"
              >
                <c-box color="#fff">Speed (hours/sec)</c-box>
                <c-input
                  v-model.number="timelapseSpeed"
                  type="number"
                  @change="() => {updateTimelapseSpeed()}"
                  size="sm"
                  mb="2"/>
                <c-flex dir="row">
                  <c-button size="sm" @click="() => {
                    $refs['cev'].playBackward()
                    isPlaying = true
                  }"><c-icon name="backward"/></c-button>
                  <c-button size="sm" ml="1" @click="() => {
                    if(isPlaying) {
                      $refs['cev'].pause()
                      isPlaying = false
                    }else {
                      $refs['cev'].playForward()
                      isPlaying = true
                    }
                  }"><c-icon v-if="isPlaying" name="pause"/><c-icon v-else name="play"/></c-button>
                  <c-button size="sm" ml="1" @click="() => {
                    $refs['cev'].playForward()
                    isPlaying = true
                  }"><c-icon name="forward"/></c-button>
                </c-flex>
              </c-box>
              <CBox>
                <CHeading size="sm">3D Model Animation control</CHeading>
                <c-flex dir="row">
                  <c-button size="sm" variant-color="vue" @click="() => {
                    if($store.state.tDAnimationPlayingState == PLAY_STATE.PLAY) {
                      $store.dispatch('updateTDAnimationPlayState', PLAY_STATE.PAUSE)
                    }else {
                      $store.dispatch('updateTDAnimationPlayState', PLAY_STATE.PLAY)
                    }
                  }"><c-icon v-if="$store.state.tDAnimationPlayingState == PLAY_STATE.PLAY" name="pause"/><c-icon v-else name="play"/></c-button>
                  <c-button size="sm" variant-color="vue" ml="1" @click="() => {
                    $store.dispatch('updateTDAnimationPlayState', PLAY_STATE.STOP)
                  }"><c-icon name="stop"/></c-button>
                </c-flex>
              </CBox>
            </c-box>
          </vue-scroll>
        </c-box>
      </pop-window>
      <!-- report -->
      <pop-window
        v-if="activePopWindow=='report'"
        @close="() => {activePopWindow = -1}"
        key="capture">
        <c-box p="4" d="flex" height="100%" flex-direction="column">
          <c-flex direction="row" height="100%">
            <c-box flex="1">
              <c-button
                @click="generateCurrentViewReport"
                variant-color="vue"
                size="sm"
              >
                <c-icon name="report" mr="1" />Current View Report
              </c-button>
            </c-box>
            <c-box flex="1" height="100%">
              <vue-scroll>
                <c-box
                  v-for="info in workerInfos"
                  p="1"
                  mb="1"
                  backgroundColor="rgba(43, 140, 91, 0.8)"
                  :key="info.id">
                  <c-stack is-inline align-items="baseline">
                    <c-box flex-grow="1">
                      <h5>Report {{ info.id + 1}}</h5>
                    </c-box>
                    <c-text fontSize="xs" color="gray.200">({{info.progress}}/{{info.processLength}})</c-text>
                  </c-stack>
                  <c-progress
                    :value="(info.progress / info.processLength) * 100"
                    size="sm"
                    color="green"
                    has-stripe
                    :is-animated="!info.completed" />
                  <c-stack is-inline>
                    <c-box flex-grow="1">
                      <c-icon-button v-if="info.completed"
                        size="sm"
                        variant="link"
                        color="#fff"
                        mr="1"
                        minWidth="0"
                        aria-label="Open"
                        icon="file-open" @click.stop="openReport(info.id)" />
                    </c-box>
                    <c-icon-button
                      size="sm"
                      variant="link"
                      color="#fff"
                      minWidth="0"
                      aria-label="Terminate"
                      icon="small-close" @click.stop="terminateReport(info.id)" />
                  </c-stack>
                </c-box>
              </vue-scroll>
            </c-box>
          </c-flex>
        </c-box>
      </pop-window>
      <!-- settings -->
      <pop-window
        v-if="activePopWindow=='settings'"
        @close="() => {activePopWindow = -1}"
        key="settings"
      >
        <c-box p="4">
          <c-stack align-items="start">
            <c-text fontSize="sm">Display</c-text>
            <c-text fontSize="md" d="flex" width="100%" align-items="center" justify-content="space-between">
              <span>Label</span>
              <c-switch
                @change="() => {toggleEntityLabel()}"
                :value="true"
                :isChecked="isLabelEnabled"
                size="sm"
                mr="1"
              />
            </c-text>
            <c-text fontSize="md">
              Thumbnail
            </c-text>
            <c-text fontSize="md">
              Details
            </c-text>
            <c-divider w="7rem" />
            <c-button @click="signout" variant-color="vue"><c-icon name="signout" mr="1" />Signout</c-button>
          </c-stack>
        </c-box>
      </pop-window>
    </transition-group>
    <c-collapse :is-open="isInfoBoxOpen">
      <entity-description
        v-if="entitySelected && typeof entity.description._value == 'object'"
        :description="entity.description._value"
        :isLoading="isEntityLoading"
        @close="onInfoBoxClose"/>
      <entity-description-html
        v-if="entitySelected && typeof entity.description._value == 'string'"
        :description="entity.description._value"
        @close="onInfoBoxClose"/>
    </c-collapse>
    <c-flex id="location-bar" align="center">
      <c-box pl="3"><span class="location-label">LAT </span><span ref="latPos"></span></c-box>
      <c-box pr="3" ml="2"><span class="location-label">LON </span><span ref="lngPos"></span></c-box>
    </c-flex>
    <c-flex id="count-bar" class="info-bar" align="center">
      <c-box px="3"
        ><span class="bar-title">Assets in view: </span>
        <span>{{ entityCount }}</span>
      </c-box>
    </c-flex>
    <custom-drawer
      :isOpen="isEntityFormShown"
      @onClose="() => {isEntityFormShown = false}"
    >
      <c-heading size="md" p="1rem">Add Entity</c-heading>
      <form-add-entity ref="form-add-entity" :lat="entityForm.lat" :lng="entityForm.lng" :isBusy="isRefreshing" @onSubmit="onAddEntity"/>
    </custom-drawer>
    <ReferenceViewWindow
      v-if="activeReference"
      :reference="activeReference"
      :key="activeReference.id"
      @close="() => {activeReference = null}"
    />
  </CBox>
</template>

<script>
import {
CBox,
CButton, CCheckbox, CCloseButton, CCollapse, CDivider, CFlex, CFormControl, CFormHelperText, CFormLabel, CHeading, CIcon,
CIconButton,
CImage, CInput, CProgress, CSelect, CStack, CSwitch, CTab, CTabList, CTabPanel, CTabPanels, CTabs, CText, CTooltip
} from '@chakra-ui/vue';
import pdfMake from 'pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import CesiumViewer from '../components/cesium/CesiumViewer';
// import ExplorerPanel from './components/ExplorerPanel'
import ReportWorker from "worker-loader!../utils/report-worker.js";
import { ApiRoutes } from '../api/api_routes';
import Asset3dInput from '../components/Asset3dInput.vue';
import CustomDrawer from '../components/CustomDrawer.vue';
import EntityDescription from '../components/EntityDescription';
import EntityDescriptionHtml from '../components/EntityDescriptionHtml';
import FormAddEntity from '../components/FormAddEntity.vue';
import PopWindow from '../components/PopWindow.vue';
import References from '../components/References.vue';
import ReferenceViewWindow from '../components/ReferenceViewWindow.vue';
import { formatSightAttr } from '../utils/helpers';
import Asset3dList from '../components/Asset3dList.vue';

import { PLAY_STATE } from '../utils/cesium_model_animation_player';

const PER_PAGE = 10000;

export default {
  name: 'App',
  components: {
    CBox,
    CButton,
    CHeading,
    CStack,
    CTooltip,
    CText,
    CDivider,
    CIcon,
    CIconButton,
    CImage,
    CCloseButton,
    CFormControl,
    CFormLabel,
    CInput,
    CFormHelperText,
    CFlex,
    CCollapse,
    CCheckbox,
    CSwitch,
    CSelect,
    CTabs,
    CTabList,
    CTabPanels,
    CTab,
    CTabPanel,
    CProgress,
    CustomDrawer,
    CesiumViewer,
    // ExplorerPanel,
    EntityDescription,
    EntityDescriptionHtml,
    FormAddEntity,
    DatePicker,
    PopWindow,
    References,
    ReferenceViewWindow,
    Asset3dInput,
    Asset3dList
},
  provide() {
    return {
      cesiumViewer: this.$refs['cev']
    }
  },
  data() {
    return {
      showCzmls: false,
      activePopWindow: -1,
      isSearchFocused: false,
      isInfoBoxOpen: false,
      isRefreshing: false,
      isSigningOut: false,
      isAddPointEnabled: false,
      isLabelEnabled: false,
      isEntityFormShown: false,
      isEntityLoading: false,
      isTimeLapseEnabled: false,
      isPlaying: false,
      dateFrom: '',
      dateTo: '',
      timelapseSpeed: 72,
      activeSightingAttr: 'standard',
      activeReference: null,
      colorConfig: {},
      sightingAttrs: [],
      entitySelected: 0,
      entityCount: 0,
      czmls: [],
      activeCzmls: [],
      workerInfos: [],
      anySight: '',
      entityForm: {
        lat: '',
        lng: '',
      },
      flyToTest: {
        desLon: 138.73148465094258,
        desLat: 35.36253610808178,
        desHeight: 3626.0426275055174,
        useOrientation: true,
        useDU: false,
        dirLon: '0',
        dirLat: '0',
        dirHeight: 0,
        upLon: '0',
        upLat: '0',
        upHeight: 0,
        heading: 0.0,
        pitch: -22.5,
        roll: 166157.76058793874,
        speed: 0.1
      },
      models: []
    }
  },
  computed: {
    czmlSectionHeader() {
      return `CZMLs (${this.activeCzmls.length}/${this.czmls.length})`
    },
    entity() {
      return this.entitySelected ? this.selectedEntity : {}
    },
    PLAY_STATE() { return PLAY_STATE }
  },
  watch: {
    activeSightingAttr() {
      this.onChangeSightingAttr()
    }
  },
  mounted() {
    this.refresh()
    this.loadCZML()
    this.loadSightingAttrs()
    this.load3dAssets();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      switch(mutation.type) {
        case 'updateChecked':
          this.updateEntityVisibility(mutation.payload.value, mutation.payload.check)
          break;
        case 'toggleAllChecked':
          if(mutation.payload.check) {
            state.selects[mutation.payload.key].map(attr => {
              this.updateEntityVisibility(attr, mutation.payload.check)
            })
          }else{
            this.$store.getters.getDefaults(mutation.payload.key).map(i => i.search_char)
              .map(attr => {
                this.updateEntityVisibility(attr, mutation.payload.check)
              })
          }
          break;
        case 'addSightFilter':
        case 'removeSightFilter':
        case 'toggleActiveSightFilter':
          this.refresh()
          break;
      }
    })
    this._entities = []
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    async load3dAssets() {
      const res = await this.$authFetch(ApiRoutes.asset3d);
      const data = await res.json();
      this.models = data.map(d => d.name);
      this.rawModelData = data;
      this.$refs['cev'].add3dAssets(data);
    },
    focus3dAsset(i) {
      if(Array.isArray(this.rawModelData) && this.rawModelData[i])
        this.$refs['cev'].focus3dAsset(this.rawModelData[i]);
    },
    async remove3dAsset(i) {
      if(Array.isArray(this.rawModelData) && this.rawModelData[i]){
        try {
          await this.$authFetch(`${ApiRoutes.asset3d}/${this.rawModelData[i]._key}`, {
            method: 'DELETE'
          })
          this.rawModelData.splice(i, 1)
          this.models = this.rawModelData.map(d => d.name)
        }catch(e){
          console.log(e)
        }
      }
    },
    runFlyTo() {
      const flyToConfig = {};
      flyToConfig.destination = new this.$cesium.Cartesian3.fromDegrees(
        +this.flyToTest.desLon,
        +this.flyToTest.desLat,
        +this.flyToTest.desHeight
      )
      if(this.flyToTest.useOrientation) {
        flyToConfig.orientation = {};
        if(this.flyToTest.useDU) {
          flyToConfig.orientation.direction = new this.$cesium.Cartesian3.fromDegrees(
            +this.flyToTest.dirLon,
            +this.flyToTest.dirLat,
            +this.flyToTest.dirHeight
          );
          flyToConfig.orientation.up = new this.$cesium.Cartesian3.fromDegrees(
            +this.flyToTest.upLon,
            +this.flyToTest.upLat,
            +this.flyToTest.upHeight
          )
        }else{
          flyToConfig.orientation = {
            heading: this.$cesium.Math.toRadians(+this.flyToTest.heading),
            pitch: this.$cesium.Math.toRadians(+this.flyToTest.pitch),
            roll: this.$cesium.Math.toRadians(+this.flyToTest.roll)
          }
        }
      }
      this.$refs['cev'].cameraFlyTo(flyToConfig)
    },
    runOrbitAround() {
      // Lock camera to a point
      const orbitConfig = {}
      orbitConfig.center = this.$cesium.Cartesian3.fromDegrees(
        +this.flyToTest.desLon,
        +this.flyToTest.desLat,
        +this.flyToTest.desHeight
      );
      if(this.flyToTest.useOrientation) {
        
        if(this.flyToTest.useDU) {
          orbitConfig.offset = new this.$cesium.Cartesian3.fromDegrees(
            +this.flyToTest.dirLon,
            +this.flyToTest.dirLat,
            +this.flyToTest.dirHeight
          );
        }else{
          if(!(+this.flyToTest.roll)) {
            this.$toast({
              title: 'Empty roll',
              description: "Roll value is a must, without it orbit doesn't run!",
              status: 'warning',
              duration: 10000
            })
            return;
          }
          orbitConfig.offset = new this.$cesium.HeadingPitchRange(
            this.$cesium.Math.toRadians(+this.flyToTest.heading),
            this.$cesium.Math.toRadians(+this.flyToTest.pitch),
            this.$cesium.Math.toRadians(+this.flyToTest.roll)
          )
        }
      }else{
        this.$toast({
          title: 'Enable orientation',
          description: "We are using (orientation direction) or (heading, pitch, range) as camera rotation offset from the center",
          status: 'warning',
          duration: 10000
        })
        return;
      }
      this.$refs['cev'].cameraOrbitAround(orbitConfig, this.$cesium.Math.toRadians(+this.flyToTest.speed))
    },
    runCameraReset() {
      this.$refs['cev'].resetCameraPosition()
    },
    generateCurrentViewReport() {
      this.capture()
    },
    async capture() {
      try {
        let dataUrl = this.$refs['cev'].snapshot();
        const worker = new ReportWorker();
        if(!this.workers){
          this.workers = []
          this.nextReportId = 0
        }

        worker.onmessage = (e) => {
          const workerInfo = this.workerInfos.find((info) => info.id == e.data.id)
          switch(e.data.type) {
            case 'generated-content':
              var win = window.open('', '_blank');
              pdfMake.createPdf({
                content: e.data.contents,
                styles: {
                  header: {
                    fontSize: 18,
                    bold: true
                  },
                  bolder: {
                    fontSize: 10,
                    bold: true
                  }
                },
                defaultStyle: {
                  columnGap: 15,
                  fontSize: 8,
                }
              }).open({}, win);
              break;
            case 'start-process':
            case 'calculating':
              workerInfo.state = e.data.type
              break;
            case 'calculated':
              workerInfo.processLength = e.data.processLength
              break;
            case 'progress':
              workerInfo.progress = e.data.progress
              break;
            case 'completed':
              workerInfo.completed = true
              break;
          }
        }
        let workerId = this.nextReportId
        this.workers.push({
          id: workerId,
          worker
        })
        this.workerInfos.push({
          id: workerId,
          state: '',
          completed: false,
          progress: 0,
          processLength: -1
        })

        worker.postMessage({
          type: 'init',
          id: workerId,
          image: dataUrl,
          filters: this.getFiltersForReport(),
          entities: await this.getEntitiesForReport()
        })
        this.nextReportId++;
      } catch (err) {
        console.error("Error: " + err);
      }
    },
    openReport(reportId) {
      const reportWorker = this.workers.find(w => w.id == reportId)
      reportWorker.worker.postMessage({
        type: 'generated-content'
      })
    },
    terminateReport(reportId) {
      const reportWorker = this.workers.find(w => w.id == reportId)
      reportWorker.worker.terminate()
      this.workers.splice(this.workers.findIndex((w) => w.id == reportId), 1)
      this.workerInfos.splice(this.workerInfos.findIndex((w) => w.id == reportId), 1)
    },
    getFiltersForReport() {
      const filters = []
      const globalFilters = this.$store.state.SightFilters.anySights.filter(f => f.isActive)
      if(globalFilters.length) {
        filters.push({
          attr: 'Globals',
          value: globalFilters.map(f => f.value).join(',')
        })
      }
      const sightFilters = this.$store.state.SightFilters.filters.filter(f => f.isActive)
      if(sightFilters.length) {
        sightFilters.map(f => {
          let attr = f.attr.replace('sighting.gdm', '').replace('category.', '').replace(/\.(.)/g, (_, b) => {
            return ' '+b.toUpperCase()
          })
          attr = attr.charAt(0).toUpperCase() + attr.slice(1).replace(/((?<![.| ])[A-Z])/g, " $1")

          let value = ''
          if(f.attr == 'day') {
            value = this.$moment(f.value).format('MMMM Do, YYYY')
          }else if(f.attr == 'month') {
            value = this.$moment(f.value).format('MMMM, YYYY')
          }else if(f.attr == 'year') {
            value = this.$moment(f.value).format('YYYY')
          }else if(f.attr == 'time') {
            value = this.$moment(f.value).format('dddd, MMMM Do, YYYY, h:mm a')
          }else {
            value = f.value
          }
          filters.push({
            attr,
            value
          })
        })
      }
      return filters
    },
    async getEntitiesForReport() {
      const obs = this.$refs['cev'].getObservationsForReport()
      if(obs.length > 0) return obs[0]
      return []
    },
    togglePopWindow(index) {
      this.activePopWindow = this.activePopWindow == index ? -1 : index;
    },
    dateToDisabledDates(date) {
      return date <= this.dateFrom
    },
    expand(group) {
      this.$set(group, 'expand', !group.expand)
    },
    toggleAddPointMode() {
      if(this.isAddPointEnabled) {
        this.isAddPointEnabled = false
        this.$refs['cev'].disableAddEntitySupport()
        // clear map
        this.removeAddedEntities()
      }else{
        this.isAddPointEnabled = true
        this.$refs['cev'].enableAddEntitySupport()
      }
    },
    toggleEntityLabel() {
      if(this.isLabelEnabled) {
        this.isLabelEnabled = false
        this.$refs['cev'].hideLabels()
      }else{
        this.isLabelEnabled = true
        this.$refs['cev'].showLabels()
      }
    },
    toggleTimeLapse() {
      if(this.isTimeLapseEnabled) {
        this.isTimeLapseEnabled = false
        this.refresh()
      }else{
        this.isTimeLapseEnabled = true
        this.refresh()
      }
    },
    removeAddedEntities() {
      this._entities = this._entities.reduce((data, entity) => {
        this.$refs['cev'].removeEntity(entity)
        return data
      }, [])
    },
    onInfoBoxClose() {
      this.entitySelected = 0
      this.selectedEntity = {}
      this.isInfoBoxOpen = !this.isInfoBoxOpen
    },
    onReferenceSelect(r) {
      this.activeReference = r
      this.activePopWindow = -1
    },
    onAnySightSearch() {
      if(this.anySight)
        this.$store.dispatch('addAnySightFilter', this.anySight)
      this.$nextTick(() => {
        this.anySight = ''
        this.refresh()
      })
    },
    onAnySightRemove(i) {
      this.$store.dispatch('removeAnySightFilter', i)
      this.$nextTick(() => {
        this.refresh()
      })
    },
    onAnySightToggle(i) {
      this.$store.dispatch('toggleAnySightFilter', i)
      this.$nextTick(() => {
        this.refresh()
      })
    },
    onEntitySelected(entity) {
      if(entity && entity.description) {
        this.selectedEntity = entity
        this.entitySelected += 1
        this.isInfoBoxOpen = true
      }
    },
    async onPointClick(id) {
      try {
        this.isEntityLoading = true;
        this.onEntitySelected({
          description: {
            _value: {}
          }
        })
        const res = await this.$authFetch(ApiRoutes.entityDetails(id));
        const description = await res.json();
        this.onEntitySelected({
          description: {
            _value: description
          }
        })
      }catch(e) {
        console.log(e)
      }finally{
        this.isEntityLoading = false;
      }
    },
    onMouseMove(locObj) {
      this.$refs['latPos'].textContent = locObj.lat;
      this.$refs['lngPos'].textContent = locObj.lng;
    },
    onMapClick(location) {
      if(this._entities[0]) this.$refs['cev'].removeEntity(this._entities.pop())
      this._entities.push(this.$refs['cev'].addEntity(location))
      this.entityForm.lat = location.lat
      this.entityForm.lng = location.lng
      this.isEntityFormShown = true
    },
    onChangeSightingAttr() {
      if(!this.activeSightingAttr || this.activeSightingAttr == 'standard') {
        this.$refs['cev'].resetEntityColors()
        this.colorConfig = {}
        return;
      }
      const activeSightingAttr = this.sightingAttrs.find(cc => cc._key == this.activeSightingAttr)
      this.colorConfig = this.$refs['cev'].updateEntityColors(activeSightingAttr._key)
    },
    updateEntityVisibility(attr, show) {
      this.$refs['cev'].updatePointVisibility('Observations', attr, show)
      this.$refs['cev'].updatePointVisibility('Tracks', attr, show)
    },
    updateTimelapseSpeed() {
      this.$refs['cev'].updateClockSpeed(Number(this.timelapseSpeed) * 3600)
    },
    async onAddEntity(data) {
      const files = data.sightings.filter(s => s.type == 'file')
      const {sightings, ...others} = data

      const formData = new FormData()

      Object.keys(others).map(k => {
        if(Array.isArray(data[k])) {
          formData.append(k, JSON.stringify(data[k]))
        }else{
          formData.append(k, data[k])
        }
      })

      formData.append('sightings', JSON.stringify(sightings.filter(s => s.type != 'file')))
      if(files.length) {
        for(let i=0; i<files.length; i++) {
          formData.append(`image${i}`, files[i].value)
        }
      }

      try {
        this.isRefreshing = true
        const res = await this.$authFetch(ApiRoutes.add_entity, {
          method: 'POST',
          body: formData
        })
        const body = await res.json()
        if(res.status >= 200 && res.status < 300) {
          const entity = this._entities.pop()
          if(entity) {
            entity.point.color = this.$cesium.Color.YELLOW
            entity.description = body
          }
          this.$refs['form-add-entity'].reset()
          this.entityForm.lat = ''
          this.entityForm.lng = ''
          this.$toast({
            position: 'bottom-left',
            title: 'Entity created.',
            description: "Entity has been successfully saved.",
            status: 'success',
            duration: 3000
          })
        }
      }catch(e) {
        console.log(e)
      }finally{
        this.isRefreshing = false
      }
    },
    async signout () {
      try {
        this.isSigningOut = true
        await this.$authFetch(ApiRoutes.signout, {
          method: 'POST'
        })
        localStorage.removeItem('token')
        this.$router.go(0)
      }catch(e) {
        console.log(e)
      }finally{
        this.isSigningOut = false
      }
    },
    async loadCZML() {
      try {
        const res = await this.$authFetch(ApiRoutes.czml)
        this.czmls = await res.json()
        this.activeCzmls = this.czmls.map((_, i) => i)
        this.czmlDs = this.czmls.map(j => this.$refs['cev'].addDS(j))
        if(this.czmlDs.length) {
          this.$refs['cev'].flyToDS(this.czmlDs[0])
        }

        /*const pathData = [];
        for(let i=1; i<czmlDoc.length; i++) {
          const positions = []
          for(let j=0; j<czmlDoc[i].position.cartographicDegrees.length; j+=4) {
            positions.push(this.$cesium.Cartesian3.fromDegrees(czmlDoc[i].position.cartographicDegrees[j+1], czmlDoc[i].position.cartographicDegrees[j+2]))
          }
          pathData.push({
            positions: positions,
            colors: czmlDoc[i].colors.map(c => {
              return this.$cesium.Color.fromBytes(c[0], c[1], c[2], c[3])
            })
          })
          delete czmlDoc[i].colors;
        }
        this.$refs['cev'].addDS(czmlDoc)
        this.$refs['cev'].addPoly(pathData)*/
      }catch(e) {
        console.log(e)
      }
    },
    async loadSightingAttrs() {
      try {
        const res = await this.$authFetch(ApiRoutes.sightingAttrs)
        const attrs = await res.json()
        this.sightingAttrs = attrs.map(attr => {
          return {
            _key: attr,
            title: formatSightAttr(attr)
          }
        })
        this.sightingAttrs.unshift({
          _key: 'standard',
          title: 'Standard'
        })
      }catch(e) {
        console.log(e)
      }
    },
    onCzmlToggle(val, $e) {
      if($e.target.checked) {
        this.activeCzmls.push(val)
        this.czmlDs[val].show = true
      }else{
        this.activeCzmls.splice(this.activeCzmls.indexOf(val), 1)
        this.czmlDs[val].show = false
      }
    },
    async refresh() {
      this.isRefreshing = true
      try {
        this.trackPage = 0;
        this.sightPage = 0;
        this.$refs['cev'].removeDS('Observations');
        this.$refs['cev'].removeDS('Tracks');
        this.$refs['cev'].clearPoints();

        await Promise.all([
          this.loadSightData(),
          this.loadTrackData()
        ])
        this.onChangeSightingAttr()
        if(!this.isTimeLapseEnabled) {
          // update timelapse from and to range
          // const {min, max} = this.$refs['cev'].getTimeRange()
          // if(!this.dateFrom)
          //   this.dateFrom = min
          // if(!this.dateTo) {
          //   max.setDate(max.getDate() + 1)
          //   this.dateTo = max
          // }
        }else{
          // prepare playing
          this.$refs['cev'].pause()
          this.isPlaying = false
          this.$refs['cev'].prepareTimelapse()
        }
      }catch(e) {
        console.log(e)
      }finally {
        this.isRefreshing = false
      }
    },
    getQueryParams() {
      let queryArr = this.$store.state.SightFilters.filters
        .filter(q => q.isActive)
        .map(q => `${q.attr}=${encodeURIComponent(q.value)}`)

      if(this.$store.state.SightFilters.anySights.length) {
        let anySightQuery = this.$store.state.SightFilters.anySights.filter(ash => ash.isActive).map(ash => encodeURIComponent(ash.value)).join(',')
        queryArr.push(`anySighting=${anySightQuery}`)
      }
      if(this.dateFrom) {
        queryArr.push(`dateFrom=${this.$moment(this.dateFrom).utc().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')}`)
      }
      if(this.dateTo) {
        queryArr.push(`dateTo=${this.$moment(this.dateTo).utc().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')}`)
      }
      if(this.isTimeLapseEnabled) {        
        queryArr.push(`timelapseSpeed=${this.timelapseSpeed}`)
      }
      return queryArr.join('&')
    },
    async loadSightData() {
      if(!this.$refs['cev']) return
      try {
        let body = this.getQueryParams();
        body=`&page=${this.sightPage}`;
        this.sightPage += 1;
        const res = await this.$authFetch(
          this.isTimeLapseEnabled
            ? ApiRoutes.timelapseQueryData('obs', body)
            : ApiRoutes.queryPointData('obs', body)
        )
        const json = await res.json()
        await this.$refs['cev'].addPoints(json)
        if(this.isLabelEnabled) {
          this.$refs['cev'].showLabels()
        }
        
        if (!this.isTimeLapseEnabled && json.length >= PER_PAGE) {
          await this.loadSightData();
        }
      }catch(e) {
        console.log(e)
      }
    },
    async loadTrackData() {
      if(!this.$refs['cev']) return
      try {
        let body = this.getQueryParams();
        body=`&page=${this.trackPage}`;
        this.trackPage += 1;
        const res = await this.$authFetch(
          this.isTimeLapseEnabled
            ? ApiRoutes.timelapseQueryData('track', body)
            : ApiRoutes.queryData('track', body)
          )
        const json = await res.json()
        this.$refs['cev'].addDS(json)
        if (!this.isTimeLapseEnabled && json.length >= PER_PAGE) {
          await this.loadTrackData();
        }
      }catch(e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
#menubar {
  position: absolute;
  right: 10px;
  top: 50px;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(30, 106, 68, 0.8);
  padding: 0.5rem;
  color: #fff;
}
#main-navigation {
  position: absolute;
  top: 7px;
  left: 0;
  width: 100%;
  .nav-logo {
    position: absolute;
    top: 0;
    filter: drop-shadow(0px 0px 1px rgba(0,0,0, 0.5));
  }
}
#filter-container {
  // position: absolute;
  // top: 0rem;
  // left: 23rem;
  // width: calc(100% - 23rem - 10rem);
  overflow-y: hidden;
  // color: #fff;
}
.container-map {
  max-width: 100%;
  transition: max-width .7s;
}
.container-map.entity-form-open{
  max-width: calc(100% - var(--entity-drawer-width, 18rem));
}
.container-sub-action {
  background: rgb(30, 106, 68) !important;
  [role=menuitem] {
    &:focus {
      background: hsl(156, 82.8%, 40%)
    }
  }
}
.close-btn {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity ease-in-out 0.3s;
  &:hover {
    opacity: 1;
  }
}
.filter-item {
  padding: 0.5rem 1rem;
  color: rgb(172, 172, 172);
  cursor: pointer;
  &--active {
    background: rgba(30, 106, 68, 0.7);
  }
  .filter-item__value {
    color: #fff;
  }
  &__attr{
    font-size: 0.8rem;
  }
  &__value {
    font-size: 1rem;
  }
}
#location-bar {
  position: absolute;
  right: 0.2rem;
  bottom: 1.8rem;
  border-radius: 15px;
  background-color: rgba(43, 140, 91, 0.8);
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.9rem;
}
#count-bar {
  position: absolute;
  left: 0.2rem;
  bottom: 4rem;
}
.info-bar {
  border-radius: 15px;
  background-color: rgba(43, 140, 91, 0.8);
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 0.9rem;
}
.location-label {
  font-weight: bold;
}
.brand-menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 350px;
  height: 50px;
  padding: 0 1rem;
  text-align: left;
  background-color: rgba(30, 106, 68, 1);
  font-weight: bold;
  font-size: 1.5em;
  color: #FFFFFF;
}
.popwindow-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
}
.explorer-panel {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 70px;
  width: 350px;
  background-color: rgba(30, 106, 68, 0.5); /*CHANGE MADE HERE*/
  overflow-y: auto;
  max-height: calc(100% - 120px);
  pointer-events: auto;
  overflow-x: hidden;
  border-radius: 0 5px 5px 0;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.8);
}
.vertical-tabs {
  display: flex;
  flex-direction: row;
  height: 100%;
  [role="tablist"] {
    border-bottom: 0;
    border-right-width: 2px;
    border-right-style: solid;
  }
  [role="tab"] {
    border-bottom: 0;
    margin-bottom: unset;
    border-right: 2px solid transparent;
    margin-right: -2px;
    width: 7rem;
    &:first-child {
      border-radius: 10px 0 0 0;
    }
    &[aria-selected="true"] {
      border-right-color: rgba(30, 106, 68, 1);
      background-color: rgba(30, 106, 68, 1);
      color: #fff;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .tab-panels {
    flex: 1 1 0%;
    border-radius: 0 10px 10px 0;
    padding: 0.7rem;
  }
}
.mx-datepicker{
  width: 100%;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.popupwindow-enter-active {
  transition: all 0.3s ease;
}
.popupwindow-leave-active {
  transition: all 0.3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.popupwindow-enter, .popupwindow-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translate(-50%, -10px) !important;
  opacity: 0;
}
.__vuescroll{
  /* scroll fix for chrome */
  display: flex;
  flex-direction: column;
}
</style>
