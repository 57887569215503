<template>
  <vue-scroll>
    <form @submit.prevent="onSubmit" class="entity-form">
      <c-stack spacing="1">
        <CFormControl :isRequired="!!$v.latitude.$params.required" :isInvalid="$v.latitude.$error">
          <CFormLabel for="lat">Latitude</CFormLabel>
          <CInput type="text" id="lat" v-model.number="$v.latitude.$model" />
          <CFormErrorMessage v-if="!$v.latitude.required">Latitude is required</CFormErrorMessage>
        </CFormControl>
        <CFormControl :isRequired="!!$v.longitude.$params.required" :isInvalid="$v.longitude.$error">
          <CFormLabel for="lng">Longitude</CFormLabel>
          <CInput type="text" id="lng" v-model.number="$v.longitude.$model" />
          <CFormErrorMessage v-if="!$v.longitude.required">Longitude is required</CFormErrorMessage>
        </CFormControl>
        <c-stack spacing="1">
          <c-heading size="sm">Sightings</c-heading>
          <CFormControl
            v-for="(v, i) in $v.sightings.$each.$iter"
            :isRequired="!!v.value.$params.required" :isInvalid="v.$error"
            :key="i">
            <c-stack direction="horizontal" spacing="0">
              <CFormLabel :for="`sighting-${sightings[i].field}-${i}`" flex="1">{{sightings[i].title}}</CFormLabel>
              <c-close-button v-if="sightings[i].type == 'file'" @click="() => {sightings.splice(i, 1)}" size="sm" />
            </c-stack>
            <template v-if="sightings[i].type == 'text'">
              <CInput
                type="text"
                :id="`sighting-${sightings[i].field}-${i}`"
                :placeholder="sightings[i].placeholder"
                v-model="v.value.$model" />
            </template>
            <template v-else-if="sightings[i].type == 'textarea'">
              <CTextarea
                :id="`sighting-${sightings[i].field}-${i}`"
                :placeholder="sightings[i].placeholder"
                :value="v.value.$model"
                @change="(val) => {
                  v.value.$model = val
                }"/>
            </template>
            <template v-else-if="sightings[i].type == 'file'">
              <custom-file-input
                v-model="v.value.$model"
                :accept="sightings[i].accept"
                :refId="`sighting-${sightings[i].field}-${i}`"
              />
            </template>
            <template v-else-if="sightings[i].type == 'select'">
              <c-select
                v-model="v.value.$model"
                :placeholder="sightings[i].placeholder"
                size="sm">
                <option v-for="(option, i) in sightings[i].options" :value="option.value" :key="i">{{option.title}}</option>
              </c-select>
            </template>
            <template v-else>
              <CInput
                type="text"
                :id="`sighting-${sightings[i].field}-${i}`"
                v-model="v.value.$model" />
            </template>
            <CFormErrorMessage v-if="!v.value.required">{{sightings[i].title}} is required</CFormErrorMessage>
          </CFormControl>
        </c-stack>
        <c-stack :spacing="2" direction="row" mt="2">
          <c-select v-model="selectedOption" placeholder="Select a field" size="sm">
            <option v-for="(option, i) in options" :value="i" :key="i">{{option.title}}</option>
          </c-select>
          <c-button size="sm" @click="addField">+</c-button>
        </c-stack>
      </c-stack>
      <CFormControl
        v-if="!$v.sightings.maxType"
        :isInvalid="!$v.sightings.maxType">
        <CFormErrorMessage>Sightings can have max {{$v.sightings.$params.maxType.count}} {{$v.sightings.$params.maxType.inputType}}</CFormErrorMessage>
      </CFormControl>
      <c-flex mt="3">
        <c-button type="submit" variant-color="vue" :loading="isBusy">Save</c-button>
      </c-flex>
    </form>
  </vue-scroll>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import {
  CHeading,
  CFlex,
  CStack,
  CSelect,
  CCloseButton,
  CTextarea,
  CFormControl, CFormLabel, CInput, CFormErrorMessage, CButton} from '@chakra-ui/vue'
import { helpers } from 'vuelidate/lib/validators'
import CustomFileInput from './CustomFileInput.vue'

const maxType = (inputType, count) => helpers.withParams(
  {
    type: 'maxType',
    inputType,
    count
  },
  (_, vm) => {
    const l = vm.sightings.filter(s => s.type == inputType).length
    return l <= count
  }
)

export default {
  props: {
    lat: String,
    lng: String,
    isBusy: Boolean
  },
  components: {
    CHeading,
    CFlex,
    CStack,
    CSelect,
    CCloseButton,
    CFormControl,
    CFormLabel,
    CInput,
    CTextarea,
    CFormErrorMessage,
    CButton,
    CustomFileInput
  },
  data() {
    return {
      latitude: '',
      longitude: '',
      sightings: [{
        title: 'Building',
        field: 'gdmBuilding',
        type: 'text',
        placeholder: 'Building name',
        value: null
      },{
        title: 'Floor',
        field: 'gdmFloor',
        type: 'text',
        placeholder: 'Floor',
        value: null
      },{
        title: 'Room Number',
        field: 'gdmRoomNumber',
        type: 'text',
        placeholder: 'Room number',
        value: null
      },{
        title: 'Asset',
        field: 'gdmAsset',
        type: 'text',
        placeholder: 'Asset',
        value: null
      },{
        title: 'Condition',
        field: 'gdmCondition',
        type: 'select',
        placeholder: 'Select a condition',
        value: null,
        options: [{
          title: 'Excellent',
          value: 'Excellent'
        }, {
          title: 'Good',
          value: 'Good'
        },{
          title: 'Reasonable',
          value: 'Reasonable'
        },{
          title: 'Poor',
          value: 'Poor'
        },{
          title: 'Very poor',
          value: 'Very poor'
        }]
      },{
        title: 'Note',
        field: 'gdmNotes',
        type: 'textarea',
        value: ''
      }],
      options: [{
        title: 'Image',
        field: 'image',
        type: 'file',
        accept: 'image/jpeg'
      }],
      selectedOption: ''
    }
  },
  watch: {
    lat (v) {
      this.$v.latitude.$model = v
    },
    lng (v) {
      this.$v.longitude.$model = v
    }
  },
  validations() {
    return {
      latitude: {
        required
      },
      longitude: {
        required
      },
      sightings: {
        required,
        maxType: maxType('file', 3),
        $each: {
          value: {
            required
          }
        }
      }
    }
  },
  methods: {
    reset() {
      this.sightings.map(s => {
        s.value = null
      })
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    addField() {
      this.sightings.push({
        title: this.options[this.selectedOption].title,
        field: this.options[this.selectedOption].field,
        type: this.options[this.selectedOption].type,
        accept: this.options[this.selectedOption].accept,
        placeholder: this.options[this.selectedOption].placeholder,
        options: this.options[this.selectedOption].options,
        value: null
      })
    },
    onSubmit() {
      this.$v.$touch()
      if(!this.$v.$invalid) {
        this.$emit('onSubmit', {
          latitude: this.latitude,
          longitude: this.longitude,
          sightings: this.sightings.map(s => {
            return {
              field: s.field,
              value: s.value,
              type: s.type
            }
          })
        })
      }
    }
  }
}
</script>

<style scoped>
.entity-form {
  padding: 1rem;
}
</style>