<template>
  <div ref="viewer-container" id="viewer-container">
  </div>
</template>
<script>
import CesiumNavigation from "cesium-navigation-es6";
import { ColorGen } from '../../utils/helpers';
import {AnimationParser, AnimationPlayer, LOOP_TYPE, PLAY_STATE} from '../../utils/cesium_model_animation_player.js';
import { AnimationManager } from "../../utils/cesium_model_animation_manager";

export default {
  props: {
    openingPosition: {
      type: Array
    }
  },
  watch: {
    '$store.state.tDAnimationPlayingState': function (pState) {
      this.update3dModelAnimationState(pState)
    }
  },
  mounted() {
    this.viewer = new this.$cesium.Viewer(this.$refs['viewer-container'], {
      infoBox: false, // kill the standard infobox so the gdmViewer can launch
      homeButton: false,
      navigationHelpButton: false,
      // timeline: false,
      fullscreenButton: false,
      animation: false,
      imageryProvider: this.$cesium.createWorldImagery({
        style: this.$cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS
      }),
      // terrainProvider: this.$cesium.createWorldTerrain(),
      // baseLayerPicker : false
    })
    this.scene = this.viewer.scene
    // this.scene.globe.depthTestAgainstTerrain = true;
    // if(Array.isArray(this.openingPosition) && this.openingPosition.length > 2) {
    //   const rect = this.$cesium.Rectangle.fromDegrees(...this.openingPosition);
    //   this.viewer.camera.setView({
    //     destination: rect,
    //     orientation: {
    //       pitch: this.$cesium.Math.toRadians(-20)
    //     }
    //   })
    // }
    this.animationManager = new AnimationManager(this.viewer);
    this.initNavigation()
    this._attachEntitySelectListener()
    this._attachMouseMove()
    this._attachPrimitivePointSelectListener();

    this.init3dModelAnimation();

    this.asset3dDataSource = new this.$cesium.CustomDataSource('asset-3d');
    this.viewer.dataSources.add(this.asset3dDataSource);
    this.pointsCollection = this.scene.primitives.add(new this.$cesium.PointPrimitiveCollection());
  },
  beforeDestroy() {
    this.viewer.dataSources.removeAll()
    this.viewer.entities.removeAll()
    this._removeClickListener();
    this._removePrimitivePointSelectListener();
    if(this.animationManager)
      this.animationManager.onDestroy();
  },
  methods: {
    initNavigation() {
      var options = {};
      options.defaultResetView = {
        destination: this.$cesium.Rectangle.fromDegrees(...this.openingPosition),
        orientation: {
          pitch: this.$cesium.Math.toRadians(-20)
        }
      };
      options.enableCompass = true;
      options.enableZoomControls = true;
      options.enableDistanceLegend = true;
      options.enableCompassOuterRing = true;
      CesiumNavigation(this.viewer, options);
    },
    async init3dModelAnimation() {
      await this.animationManager.add({
        name: 'Cat',
        url: `${process.env.VUE_APP_ASSETS_3D_BASE}/animated_bengal_cat.glb`,
        position: this.$cesium.Cartesian3.fromDegrees(-123.0744619, 44.0503706, 0)
      })

      await this.animationManager.add({
        name: 'Giraffe',
        url: `${process.env.VUE_APP_ASSETS_3D_BASE}/Giraffe.glb`,
        position: this.$cesium.Cartesian3.fromDegrees(-123.0744619, 44.0503706, 0),
        scale: 0.00025,//0.00025
        track: true
      })

      this.animationManager.play();
    },
    update3dModelAnimationState(pState) {
      if(pState == PLAY_STATE.PLAY) {
        this.animationManager.play();
      }else if(pState == PLAY_STATE.PAUSE) {
        this.animationManager.pause();
      }else{
        this.animationManager.stop();
      }
    },
    getObservationsForReport() {
      return this.viewer.dataSources.getByName('Observations').map(ds => {
        return ds.entities.values.map(e => e.description._value)
      })
    },
    removeDS(dataSourceName) {
      try {
        this._checkViewer()
        this.viewer.dataSources
          .getByName(dataSourceName)
          .map(ds => {
            this.viewer.dataSources.remove(ds, true);
          })
      } catch (e) {
        console.log(e)
      }
    },
    async addDS(jsonData) {
      this._checkViewer()
      const ds = new this.$cesium.CzmlDataSource()
      const ads = await this.viewer.dataSources.add(ds)
      const nds = await ads.load(jsonData);
      nds.entities.values.map(entity => {
        if (
          entity.description &&
          typeof entity.description._value == 'object' &&
          entity.description._value.sighting &&
          entity.description._value.sighting.gdmAsset) {
          entity.label = {
            text: entity.description._value.sighting.gdmAsset,
            font: '15px',
            horizontalOrigin: this.$cesium.HorizontalOrigin.LEFT,
            verticalOrigin: this.$cesium.VerticalOrigin.BOTTOM,
            translucencyByDistance: new this.$cesium.NearFarScalar(
              1.5e5,
              1.0,
              1.5e7,
              0.0
            ),
            show: false
          }
        }
      })
      return nds;
    },
    async addPoints(points) {
      points.forEach(point => {
        this.pointsCollection.add({
          id: point.id,
          position: this.$cesium.Cartesian3.fromDegrees(point.position[0], point.position[1], point.position[2]),
          color: this.$cesium.Color.fromBytes(point.color[0], point.color[1], point.color[2], point.color[3]),
          pp: true
        })
      })
      this.$emit('entityCountUpdated', this.pointsCollection.length)
    },
    clearPoints() {
      this.pointsCollection.removeAll()
    },
    addPoly(pathData) {
      pathData.map(p => {
        // console.log(p)
        this.scene.primitives.add(
          new this.$cesium.Primitive({
            geometryInstances: new this.$cesium.GeometryInstance({
              geometry: new this.$cesium.PolylineGeometry({
                positions: p.positions,
                width: 5.0,
                vertexFormat: this.$cesium.PolylineColorAppearance.VERTEX_FORMAT,
                colors: p.colors,
                colorsPerVertex: true,
              }),
            }),
            appearance: new this.$cesium.PolylineColorAppearance(),
          })
        );
      })
    },
    removeEntity(entity) {
      this._checkViewer()
      this.viewer.entities.remove(entity)
    },
    addEntity(position) {
      this._checkViewer()
      return this.viewer.entities.add({
        name: `Entity-${this.viewer.entities.length + 1}`,
        position: this.$cesium.Cartesian3.fromDegrees(parseFloat(position.lng), parseFloat(position.lat)),
        point: {
          pixelSize: 10,
          color: this.$cesium.Color.WHITE,
          outlineWidth: 2,
          outlineColor: this.$cesium.Color.BLACK
        }
      })
    },
    updateEntityColors(attr) {
      let vDB = {};
      let colorgen = new ColorGen(50);
      this.viewer.dataSources.getByName('Observations').map(ds => {
        ds.entities.values.map(entity => {
          const des = entity.description._value
          const v = des.sighting[attr]
          if (v) {
            if (vDB[v]) {
              entity.point.color = vDB[v];
              return;
            } else {
              vDB[v] = this.$cesium.Color.fromCssColorString(colorgen.nextColor())
              entity.point.color = vDB[v]
              return;
            }
          } else if (des.extras.length) {
            let ex = des.extras.find((ex) => {
              return ex['segCombo'][attr] || ex['finValues'][attr] || ex['otherCategories'][attr]
            })
            if (ex) {
              let v = ex['segCombo'][attr] || ex['finValues'][attr] || ex['otherCategories'][attr]
              if (vDB[v]) {
                entity.point.color = vDB[v];
                return;
              } else {
                vDB[v] = this.$cesium.Color.fromCssColorString(colorgen.nextColor())
                entity.point.color = vDB[v]
                return;
              }
            }
          }
          // reset entity color
          entity.point.color = this.$cesium.Color.fromBytes(255, 255, 0, 255)
        })
      })
      return vDB;
    },
    resetEntityColors() {
      this.viewer.dataSources.getByName('Observations').map(ds => {
        ds.entities.values.map(entity => {
          entity.point.color = this.$cesium.Color.fromBytes(255, 255, 0, 255)
        })
      })
    },
    add3dAssets(assets) {
      this.asset3dDataSource.entities.removeAll();
      assets.forEach(asset => {
        const position = this.$cesium.Cartesian3.fromDegrees(
          asset.config.position[0],
          asset.config.position[1],
          asset.config.position[2]
        );
        const heading = this.$cesium.Math.toRadians(asset.config.heading);
        const pitch = this.$cesium.Math.toRadians(asset.config.pitch);
        const roll = this.$cesium.Math.toRadians(asset.config.roll);
        const hpr = new this.$cesium.HeadingPitchRoll(heading, pitch, roll);
        const orientation = this.$cesium.Transforms.headingPitchRollQuaternion(
          position,
          hpr
        );

        const entity = this.asset3dDataSource.entities.add({
          id: asset._key,
          name: asset.name,
          position: position,
          orientation: orientation,
          model: {
            uri: `${process.env.VUE_APP_ASSETS_3D_BASE}/${asset.name}`,
            minimumPixelSize: 128,
            // maximumScale: 20000,
          },
        });
        // this.flyToDS(entity)
      })
    },
    focus3dAsset(asset) {
      const entity = this.asset3dDataSource.entities.getById(asset._key)
      console.log('f3d', entity);
      if(entity)
        this.flyToDS(entity)
    },
    showLabels() {
      this.viewer.dataSources.getByName('Observations').map(ds => {
        ds.entities.values.map(entity => {
          if (entity.label)
            entity.label.show = true
        })
      })
    },
    hideLabels() {
      this.viewer.dataSources.getByName('Observations').map(ds => {
        ds.entities.values.map(entity => {
          if (entity.label)
            entity.label.show = false
        })
      })
    },
    zoomTo(dataSource) {
      this._checkViewer()
      this.viewer.zoomTo(dataSource)
    },
    flyToDS(dataSource) {
      this._checkViewer()
      this.viewer.flyTo(dataSource)
    },
    cameraFlyTo(config) {
      this._checkViewer()
      this.viewer.camera.flyTo(config)
    },
    async cameraOrbitAround(config, speed = 0.005) {
      return new Promise((resolve, reject) => {
        var transform = this.$cesium.Transforms.eastNorthUpToFixedFrame(config.center);
        this.viewer.scene.camera.lookAtTransform(transform, config.offset);
        this.orbitSpeed = speed;

        const _rotateRight = (_) => {
          this.viewer.scene.camera.rotateRight(this.orbitSpeed);
          this.orbitCompleteAmount += this.orbitSpeed;
          if (this.orbitCompleteAmount >= this.orbitAmount) {
            this.viewer.clock.onTick.removeEventListener(_rotateRight);
            this.viewer.camera.lookAtTransform(this.$cesium.Matrix4.IDENTITY);
            resolve();
          }
        }

        // Orbit this point
        this.orbitAmount = Math.PI * 2 * config.orbitCount;
        this.orbitCompleteAmount = 0;
        this.viewer.clock.onTick.removeEventListener(_rotateRight);
        this.viewer.clock.onTick.addEventListener(_rotateRight);
      })
    },
    async flyToBoundingSphere(sphere, options) {
      console.log('ssee', sphere, options)
      return new Promise((resolve, reject) => {
        options = options ?? {};
        let onComplete = () => { };
        let onCancel = () => { };
        if (typeof options.complete == 'function') {
          onComplete = options.complete;
        }
        if (typeof options.cancel == 'function') {
          onCancel = options.cancel;
        }
        options.complete = () => {
          onComplete();
          resolve()
        }
        options.cancel = () => {
          onCancel();
          reject();
        }
        console.log('ssshh', sphere, options)
        this.viewer.camera.flyToBoundingSphere(sphere, options)
      })
    },
    testAnim() {
      const alu = (queuedTileCount) => {
        // console.log(queuedTileCount);
        // console.log(viewer.scene.globe.tilesLoaded);

        if (this.viewer.scene.globe.tilesLoaded) {
          this.viewer.scene.globe.tileLoadProgressEvent.removeEventListener(alu)
          this.runAnim()
        }
      };
      this.viewer.scene.globe.tileLoadProgressEvent.addEventListener(alu);
    },
    async runAnim() {
      const config = [
        {
          "action": "flyToAndOrbit",
          "destination": [
            30.382276911190218,
            -29.601268764825882,
            0
          ],
          "orbitCount": 2,
          "orbitSpeed": 0.005,
          "orbitOffset": [
            0,
            -0.8,
            1000
          ]
        },
        {
          "action": "moveto",
          "destination": [
            50.38227691119022,
            -50.60126876482588,
            100
          ],
          "transit": [
            "314325"
          ],
          "duration": 60
        }
      ];
      for (let data of config) {
        console.log('ss', data)
        switch (data.action) {
          case 'flyToAndOrbit':
            console.log('ss1')
            // await this.flyToBoundingSphere(
            //   new this.$cesium.BoundingSphere(data.destination, 0),
            //   {
            //     offset: data.orbitOffset,
            //   }
            // )
            console.log('ss2')
            // await this.cameraOrbitAround(data, data.orbitSpeed)
            break;
          case 'moveto':
            console.log('mvt');
            var width = this.scene.canvas.clientWidth;
            var height = this.scene.canvas.clientHeight;
            var windowPosition = new this.$cesium.Cartesian2(width / 2, height / 2);
            var pickRay = this.viewer.scene.camera.getPickRay(windowPosition);
            var pickPosition = this.viewer.scene.globe.pick(pickRay, this.scene, new this.$cesium.Cartesian3());
            // console.log('pick', pickPosition, windowPosition, pickRay)
            var pickPositionCartographic = this.scene.globe.ellipsoid.cartesianToCartographic(pickPosition);
            // console.log(pickPositionCartographic.longitude * (180/Math.PI));
            // console.log(pickPositionCartographic.latitude * (180/Math.PI));
            console.log('mvt1');
            const start = this.$cesium.JulianDate.now(new this.$cesium.JulianDate());
            const duration = data.duration;
            const stop = this.$cesium.JulianDate.addSeconds(start.clone(), duration, new this.$cesium.JulianDate());
            const property = new this.$cesium.SampledPositionProperty();
            const stops = [];
            stops.push([pickPositionCartographic.latitude * (180 / Math.PI), pickPositionCartographic.longitude * (180 / Math.PI), pickPositionCartographic.height])
            if (Array.isArray(data.transit) && data.transit.length) {
              data.transit.forEach(tr => {
                const entity = this.asset3dDataSource.entities.getById(tr);
                if (entity) {
                  const cart = this.scene.globe.ellipsoid.cartesianToCartographic(entity.position._value);
                  stops.push([cart.latitude * (180 / Math.PI), cart.longitude * (180 / Math.PI), cart.height]);
                }
              })
            }
            console.log('mvt2', data.destination);
            stops.push(data.destination);

            const distance = (p1, p2) => {
              if (!p1 || !p2) return 0;
              console.log('p1', p1, 'p2', p2);
              var startCartesian3Point = this.$cesium.Cartesian3.fromDegrees(p1[0], p1[1], p1[2] || 0);
              var endCartesian3Point = this.$cesium.Cartesian3.fromDegrees(p2[0], p2[1], p2[2] || 0);

              var startCartographicPoint = this.$cesium.Cartographic.fromCartesian(startCartesian3Point);
              var endCartographicPoint = this.$cesium.Cartographic.fromCartesian(endCartesian3Point);

              var ellipsoidGeodesic = new this.$cesium.EllipsoidGeodesic(startCartographicPoint,
                endCartographicPoint);
              return ellipsoidGeodesic.surfaceDistance;
            }
            console.log('sstops', stops)
            const totalDistance = stops.reduce((sum, pos, i) => {
              return sum + distance(stops[i - 1] || null, pos)
            }, 0);

            console.log('mvt3');

            const time = this.$cesium.JulianDate.addSeconds(
              start,
              0,
              new this.$cesium.JulianDate()
            );
            property.addSample(time, this.$cesium.Cartesian3.fromDegrees(stops[0][1], stops[0][0], stops[0][2]));

            console.log('mv121');

            for (let i = 1; i < stops.length; i++) {
              const l = distance(stops[i - 1], stops[i]);
              const dt = (duration / totalDistance) * l;
              console.log('dt', dt, l)
              const t = this.$cesium.JulianDate.addSeconds(
                start,
                dt,
                new this.$cesium.JulianDate()
              );
              property.addSample(t, this.$cesium.Cartesian3.fromDegrees(stops[i][1], stops[i][0], stops[i][2]));
            }

            console.log('mvt4', property);
            //Actually create the entity
            const entity = this.viewer.entities.add({
              id: 'follow',
              name: 'au',
              //Set the entity availability to the same interval as the simulation time.
              // availability: new this.$cesium.TimeIntervalCollection([
              //   new this.$cesium.TimeInterval({
              //     start: start,
              //     stop: stop,
              //   }),
              // ]),

              //Use our computed positions
              position: property,
              point: {
                pixelSize: 10,
                color: this.$cesium.Color.TRANSPARENT,
                outlineColor: this.$cesium.Color.YELLOW,
                outlineWidth: 3,
              },
              //Show the path as a pink line sampled in 1 second increments.
              path: {
                resolution: 1,
                material: new this.$cesium.PolylineGlowMaterialProperty({
                  glowPower: 0.1,
                  color: this.$cesium.Color.YELLOW,
                }),
                width: 10,
              },
            });
            setTimeout(() => {
              this.viewer.clockViewModel.startTime = start;
              this.viewer.clockViewModel.stopTime = stop;
              this.viewer.clockViewModel.currentTime = this.viewer.clockViewModel.startTime;
              this.viewer.timeline.zoomTo(this.viewer.clockViewModel.startTime, this.viewer.clockViewModel.stopTime)
              this.viewer.clockViewModel.shouldAnimate = true
              // this.viewer.zoomTo(this.viewer.entities);
              this.viewer.trackedEntity = entity;
            }, 10000)
            console.log('sss la')
            break;
          default:
            break;
        }
      }
    },
    resetCameraPosition() {
      this.viewer.clock.onTick.removeEventListener(this._rotateRight);
      this.cameraFlyToHome();
    },
    cameraFlyToHome() {
      this.viewer.camera.flyHome()
    },
    updatePointVisibility(dataSourceName, attr, show) {
      this._checkViewer()
      this.viewer.dataSources
        .getByName(dataSourceName)
        .map(ds => {
          ds.entities.values
            .filter(entity => entity.description._value.indexOf(attr) > -1)
            .map(entity => {
              entity.point.show = show
            })
        })
    },
    disableAddEntitySupport() {
      this._removeClickListener()
    },
    enableAddEntitySupport() {
      this._attachClickListener()
    },
    pause() {
      if (this.animationViewModel && this.viewer.clockViewModel.shouldAnimate)
        this.animationViewModel.pauseViewModel.command();
    },
    playForward() {
      if (this.animationViewModel)
        this.animationViewModel.playForwardViewModel.command();
    },
    playBackward() {
      if (this.animationViewModel)
        this.animationViewModel.playReverseViewModel.command();
    },
    updateClockSpeed(speed) {
      this.viewer.clockViewModel.multiplier = speed
    },
    prepareTimelapse() {
      this.animationViewModel = new this.$cesium.AnimationViewModel(this.viewer.clockViewModel);
      this.viewer.timeline.addEventListener('settime', (e) => {
        this.$emit('timeline-scrub', e)
      }, false);
      const range = this.getTimelapseRange();
      this.viewer.clockViewModel.startTime = this.$cesium.JulianDate.fromDate(range.min)
      this.viewer.clockViewModel.stopTime = this.$cesium.JulianDate.fromDate(range.max)
      this.viewer.clockViewModel.currentTime = this.viewer.clockViewModel.startTime;
      this.viewer.timeline.zoomTo(this.viewer.clockViewModel.startTime, this.viewer.clockViewModel.stopTime)
      // clockViewModel.shouldAnimate = true;

    },
    getTimeRange() {
      const range = {
        min: new Date(),
        max: new Date(0)
      }
      this.viewer.dataSources.getByName('Observations')
        .map(ds => {
          ds.entities.values
            .filter(entity => typeof entity.description._value == 'object')
            .map(entity => {
              const des = entity.description._value
              if (this.$moment(des.dateTime).isAfter(range.max)) {
                range.max = new Date(des.dateTime)
              }
              if (this.$moment(des.dateTime).isBefore(range.min)) {
                range.min = new Date(des.dateTime)
              }
            })
        })
      return range
    },
    getTimelapseRange() {
      const range = {
        min: '',
        max: new this.$cesium.JulianDate(0)
      }
      this.viewer.dataSources.getByName('Observations')
        .map(ds => {
          range.min = ds.entities.values.length > 0 ? ds.entities.values[0].position._property._times[0] : this.$cesium.JulianDate()
          ds.entities.values
            .map(entity => {
              const times = entity.position._property._times
              if (this.$cesium.JulianDate.compare(range.min, times[0]) > 0) {
                range.min = times[0]
              }
              if (this.$cesium.JulianDate.compare(range.max, times[times.length - 1]) < 0) {
                range.max = times[times.length - 1]
              }
            })
        })
      return range
    },
    snapshot() {
      this.viewer.render()
      return this.viewer.canvas.toDataURL()
    },
    _attachEntitySelectListener() {
      this.$cesium.knockout.getObservable(this.viewer, '_selectedEntity').subscribe((entity) => {
        if (this.$cesium.defined(entity)) {
          this.$emit('entity-selected', entity)
        } else {
          this.$emit('entity-selected', null)
        }
      });
    },
    _attachPrimitivePointSelectListener() {
      if (this.ppClickHandler && !this.ppClickHandler.isDestroyed()) {
        this.ppClickHandler.destroy()
      }
      this.ppClickHandler = new this.$cesium.ScreenSpaceEventHandler(this.scene.canvas);
      this.ppClickHandler.setInputAction((event) => {
        var pick = this.scene.pick(event.position);
        if (this.$cesium.defined(pick) && this.$cesium.defined(pick.primitive) && pick.primitive instanceof this.$cesium.PointPrimitive) {
          this.$emit('pp-click', pick.id);
        }
      }, this.$cesium.ScreenSpaceEventType.LEFT_CLICK)
    },
    _removePrimitivePointSelectListener() {
      if (this.ppClickHandler && !this.ppClickHandler.isDestroyed()) {
        this.ppClickHandler.destroy()
      }
    },
    _removeClickListener() {
      if (this.mapClickHandler && !this.mapClickHandler.isDestroyed()) {
        this.mapClickHandler.destroy()
      }
    },
    _attachClickListener() {
      const ellipsoid = this.scene.globe.ellipsoid;
      if (this.mapClickHandler && !this.mapClickHandler.isDestroyed()) {
        this.mapClickHandler.destroy()
      }
      this.mapClickHandler = new this.$cesium.ScreenSpaceEventHandler(this.scene.canvas);
      this.mapClickHandler.setInputAction((event) => {
        const cartesian = this.scene.camera.pickEllipsoid(event.position, ellipsoid);
        if (cartesian) {
          var cartographic = ellipsoid.cartesianToCartographic(cartesian);
          this.$emit('map-click', {
            lng: this.$cesium.Math.toDegrees(cartographic.longitude).toFixed(6),
            lat: this.$cesium.Math.toDegrees(cartographic.latitude).toFixed(6)
          })
        } else {
          this.$emit('map-click', {
            lat: '',
            lng: ''
          })
        }
      }, this.$cesium.ScreenSpaceEventType.LEFT_CLICK)
    },
    _attachMouseMove() {
      const ellipsoid = this.scene.globe.ellipsoid;
      const handler = new this.$cesium.ScreenSpaceEventHandler(this.scene.canvas);
      handler.setInputAction((movement) => {
        const cartesian = this.scene.camera.pickEllipsoid(movement.endPosition, ellipsoid);
        if (cartesian) {
          var cartographic = ellipsoid.cartesianToCartographic(cartesian);
          this.$emit('mouse-move', {
            lat: this.$cesium.Math.toDegrees(cartographic.latitude).toFixed(6),
            lng: this.$cesium.Math.toDegrees(cartographic.longitude).toFixed(6)
          })
        } else {
          this.$emit('mouse-move', {
            lat: '',
            lng: ''
          })
        }
      }, this.$cesium.ScreenSpaceEventType.MOUSE_MOVE);
      //Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    _checkViewer() {
      if (!this.viewer) throw new Error('Viewer not initiated yet!')
    }
  }
}
</script>

<style lang="scss">
#viewer-container {
  height: 100%;
  width: 100vw;
}

.cesium-viewer-toolbar {
  z-index: 100;
}

/* navigation widget style customization */
.compass {
  top: unset;
  bottom: 80px;
}

.navigation-controls {
  display: inline-flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95px;
  right: 0;
  top: unset;
  bottom: 63px;
  border: 0;
}

.navigation-control,
.navigation-control-last {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: rgba(43, 140, 91, 0.8);

  &:hover {
    background: rgba(43, 140, 91, 1);
  }

  &:active {
    background: rgba(43, 140, 91, 0.8);
  }
}

.navigation-control-icon-zoom-in {
  padding: 0;
}

.navigation-control-icon-reset {
  left: unset;
}

.navigation-control:first-child {
  border-radius: 5px 0 0 5px;
  border-right: 1px solid rgba(43, 140, 91, 1) !important;
}

.navigation-control-last {
  border-radius: 0 5px 5px 0;
  border-left: 1px solid rgba(43, 140, 91, 1) !important;
}
</style>
