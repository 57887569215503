import * as Cesium from 'cesium/Cesium'

export default {
  install (Vue, options) {
    if(typeof options != 'object') throw new Error('options is required')
    if(!options.access_token) throw new Error('options access_token is required')
    if(!options.asset_path) options.asset_path = '/'
    
    window.CESIUM_BASE_URL = options.asset_path;
    Cesium.Ion.defaultAccessToken = options.access_token;

    Vue.prototype.$cesium = Cesium
  }
}