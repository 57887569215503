<template>
  <Photoswipe>
    <CBox
      v-for="(image, i) in loadedImages"
      mb="2"
      :key="`${i}`">
      <c-aspect-ratio-box :ratio="4/3">
        <c-image
          :src="image"
          object-fit="cover"
          rounded="10px"
          v-pswp="image"/>
      </c-aspect-ratio-box>
    </CBox>
  </Photoswipe>
</template>
<script>
import {
  CBox,
  CImage, CAspectRatioBox
} from '@chakra-ui/vue';
import { Photoswipe } from 'vue-pswipe';
export default {
  props: {
    images: Array
  },
  components: {
    CBox,
    CImage, CAspectRatioBox,
    Photoswipe,
  },
  data() {
    return {
      loadedImages: []
    }
  },
  mounted() {
    this.loadImages()
  },
  methods: {
    async loadImages() {
      this.loadedImages = []
      this.imageLoaded = false;
      const needToFetch = this.images;
      
      for(let i=0; i<needToFetch.length; i++) {
        const res = await this.$authFetch(`${process.env.VUE_APP_IMAGE_BASE}/${needToFetch[i]}`)
        const blob = await res.blob();
        this.loadedImages.splice(i, 1, URL.createObjectURL(blob))
      }
      this.imageLoaded = true;
    },
    getImageUrl(image) {
      return `${process.env.VUE_APP_IMAGE_BASE}/${image}`
    }
  }
}
</script>