export default {
  install (Vue) {
    async function authFetch(url, config) {
      if(!config) config = {}
      if(localStorage.getItem('token')) {
        if(!config.headers) config.headers = {}
        config.headers = Object.assign(config.headers, {
          authorization: `Bearer ${localStorage.getItem('token')}`
        })
      }
      const res = await fetch(url, config)
      if(res.status >= 401 && res.status <= 403) {
        localStorage.removeItem('token')
        this.$router.replace('/signin')
      }else{
        return res
      }
    }

    Vue.prototype.$authFetch = authFetch;
  }
}