export const ApiRoutes = {
  signin: `${process.env.VUE_APP_API_BASE}/auth/signin`,
  aad_signin: `${process.env.VUE_APP_API_BASE}/auth/aad-signin`,
  signout: `${process.env.VUE_APP_API_BASE}/auth/signout`,
  queryData(query, queryArgs) {
    return `${process.env.VUE_APP_API_BASE}/qry/${query}?${queryArgs}`
  },
  queryPointData(query, queryArgs) {
    return `${process.env.VUE_APP_API_BASE}/point-qry/${query}?${queryArgs}`
  },
  timelapseQueryData(query, queryArgs) {
    return `${process.env.VUE_APP_API_BASE}/qry-timelapse/${query}?${queryArgs}`
  },
  entityDetails(id) {
    return `${process.env.VUE_APP_API_BASE}/entities/${id}`
  },
  czml: `${process.env.VUE_APP_API_BASE}/czml`,
  colorConfig: `${process.env.VUE_APP_API_BASE}/color-config`,
  sightingAttrs: `${process.env.VUE_APP_API_BASE}/sighting-attrs`,
  add_entity: `${process.env.VUE_APP_API_BASE}/ctp-manual`,
  references: `${process.env.VUE_APP_API_BASE}/references`,
  asset3d:`${process.env.VUE_APP_API_BASE}/3d-assets`,
}