<template>
  <div id="reference-info-window">
    <CFlex
      class="infobox-toolbar"
      direction="horizontal"
      justify="space-between">
      <CHeading as="h5" size="sm">{{ reference.title }}</CHeading>
      <CCloseButton size="sm" @click="$emit('close')"/>
    </CFlex>
    <vue-scroll
      :ops="{
        scrollPanel: {
          scrollingX: false
        }
      }"
    >
      <CFlex
        direction="column"
        class="infobox-content"
      >
        <div v-for="ref in references" :key="ref.id">
          <CFlex
            direction="row"
          >
            <CBox flex="1">
              <ReferenceSightObject :item="ref" :skips="['_key', '_id', '_rev', 'type']"/>
            </CBox>
            <CBox flex="1">
              <reference-images :images="refImages(ref)" />
            </CBox>
          </CFlex>
        </div>
      </CFlex>
    </vue-scroll>
  </div>
</template>
<script>
import {
  CBox,
  CFlex,
  CHeading,
  CCloseButton,
} from '@chakra-ui/vue';
import { ApiRoutes } from '../api/api_routes';
import ReferenceSightObject from '../components/ReferenceSightObject.vue'
import ReferenceImages from './ReferenceImages.vue';

export default {
  props: {
    reference: Object
  },
  components: {
    CBox,
    CFlex,
    CHeading,
    CCloseButton,
    ReferenceSightObject,
    ReferenceImages
  },
  data() {
    return {
      references: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        const res = await this.$authFetch(`${ApiRoutes.references}/${this.reference.id}`)
        this.references = await res.json()
      }catch(e) {
        console.log(e)
      }
    },
    refImages(ref) {
      return Object.keys(ref.properties.sighting).filter(k => k.includes('Photo')).map(k => ref.properties.sighting[k].fileName)
    },
  }
}
</script>
<style lang="scss" scoped>
#reference-info-window {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50px;
  right: 12.5vw;
  width: 75vw;
  max-height: calc(100vh - 100px);
  z-index: 101;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  background: rgba(43, 140, 91, 0.8);
  border-radius: 5px;
  max-height: calc(100vh - 275px);
  box-shadow: -1px 0 10px rgba(0,0,0, 0.8);
  transition: all 1.5s ease-in-out;
  transition-property: width, max-height, top, right, left, bottom;
  .infobox-toolbar,
  .infobox-content {
    padding: 1rem;
    transition: padding 1.5s ease-in-out;
  }
  .infobox-toolbar {
    border-radius: 5px 5px 0 0;
    background:  rgba(43, 140, 91, 1);
  }
}
</style>