import { BoundingSphere, cancelAnimationFrame as cesiumCancelAnimationFrame, Cartesian3, Color, Intersect, Rectangle, requestAnimationFrame as cesiumRequestAnimationFrame } from "cesium";

import { AnimationParser, AnimationPlayer, LOOP_TYPE, PLAY_STATE } from "./cesium_model_animation_player";

export class AnimationManager {
  constructor(viewer) {
    this.viewer = viewer;
    this.players = [];
    this.viewer.camera.changed.addEventListener(this.onCameraChanged, this);
    this.cameraViewRect = new Rectangle();
    this.cameraViewBoundingSphere = new BoundingSphere();
    this.tmpEntityBoundingSphere = new BoundingSphere();
    this._update = this._update.bind(this)
    // const e = this.viewer.entities.add({
    //   position: new Cartesian3(0.8716242295313712, 0.07868708994746201, -0.4838176767848027),
    //   point: {
    //     pixelSize: 10
    //   }
    // })
    // this.viewer.trackedEntity = e;
  }
  onDestroy() {
    this.viewer.camera.changed.removeEventListener(this.onCameraChanged)
  }
  onCameraChanged() {
    if(this.play_state != PLAY_STATE.PLAY) return;
    const frustum = this.viewer.camera.frustum;
    const cullingVolume = frustum.computeCullingVolume(
      this.viewer.camera.position,
      this.viewer.camera.direction,
      this.viewer.camera.up
    );
    this.viewer.camera.computeViewRectangle(this.viewer.scene.globe.ellipsoid, this.cameraViewRect);
    BoundingSphere.fromRectangle2D(this.cameraViewRect, null, this.cameraViewBoundingSphere);
    // if(this.tmi) {
    //   clearTimeout(this.tmi)
    //   this.tmi = null
    // }
    // this.tmi = setTimeout(() => {
    //   const viewWS = this.viewer.camera.computeViewRectangle(this.viewer.scene.globe.ellipsoid, this.scratchRectangle);
    //   // console.log('vs', viewWS, 'width', Rectangle.computeWidth(viewWS), 'height', Rectangle.computeHeight(viewWS));
    //   const cBS = new BoundingSphere();
    //   BoundingSphere.fromRectangle2D(this.scratchRectangle, null, cBS);
    //   console.log('cv bs', cBS, cBS.radius);
    //   this.viewer.entities.add({
    //     position: this.players[0].entity.position,
    //     rectangle: {
    //       coordinates: viewWS,
    //       material: Color.RED.withAlpha(0.5),
    //     }
    //   })
    // }, 1000)
    for(let player of this.players) {
      this.viewer.dataSourceDisplay.getBoundingSphere(player.entity, false, this.tmpEntityBoundingSphere);
      // console.log(player.entity.name, boundingSphere.radius, Math.PI*boundingSphere.radius*boundingSphere.radius, viewArea);
      const intersection = cullingVolume.computeVisibility(this.tmpEntityBoundingSphere);
      if(intersection == Intersect.OUTSIDE) {
        console.log(player.entity.name, 'paused for outside')
        player.pause();
      }else{
        // console.log('dfdfdf', this.cameraViewBoundingSphere.radius, this.tmpEntityBoundingSphere.radius, this.cameraViewBoundingSphere.radius/this.tmpEntityBoundingSphere.radius)
        if(this.cameraViewBoundingSphere.radius/this.tmpEntityBoundingSphere.radius > 1650782 ) {
          console.log(player.entity.name, 'paused for too small')
          player.pause();
        }else{
          console.log(player.entity.name, 'resumed')
          player.resume();
        }
      }
    }
  }
  get players() {
    return this._players;
  }
  set players(arr) {
    this._players = arr;
  }
  setAnimation(index, name) {
    this.players[index].setAnimation(name)
  }
  async add({name, url, position, loop_type = LOOP_TYPE.LOOP,  scale = 1, speed = 1, track = false}) {
    const animation_set = await AnimationParser.parseAnimationSetFromUri(url);
    var entity = this.viewer.entities.add({
      //Use our computed positions
      name,
      position : position,
      //Load the Cesium plane model to represent the entity
      model : {
        uri : url,
        scale,
        runAnimations: false
      }
    });
    if(track) {
      this.viewer.zoomTo(entity);
    }

    let player = new AnimationPlayer(animation_set, entity, 60);
    // setTimeout(() => {
    //   this.ec = this.ec ? this.ec + 1 : 1;
    //   const bs = new BoundingSphere();
    //   this.viewer.dataSourceDisplay.getBoundingSphere(entity, false, bs);
    //   console.log('ssss', entity.name, entity.model.scale, bs.radius)
    //   this.viewer.entities.add({
    //     position,
    //     ellipsoid: {
    //       radii: new Cartesian3(bs.radius, bs.radius, bs.radius),
    //       material: this.ec == 1 ? Color.GREEN.withAlpha(0.5) : Color.RED.withAlpha(0.5),
    //       outline: true,
    //       outlineColor: Color.BLACK,
    //     },
    //   })
    // }, 10000)
    player.loop_type = loop_type;
    player.speed = speed;
    player.play_state = PLAY_STATE.PLAY;
    player.setAnimation(animation_set.animations[0].name);
    this.players.push(player)
  }

  play() {
    if(this.play_state === PLAY_STATE.PLAY) {
      return;
    } else {
      this.players.forEach(player => {
        player.resume();
      })
      this.play_state = PLAY_STATE.PLAY;
      this.frameId = cesiumRequestAnimationFrame(this._update)
      // this.interval_id = window.setInterval(() => this._update(), this._frame_duration * 1000);
    }
  }

  pause() {
    cesiumCancelAnimationFrame(this.frameId);
    this.play_state = PLAY_STATE.PAUSE;
    this.players.forEach(player => {
      player.pause();
    })
  }

  stop() {
    cesiumCancelAnimationFrame(this.frameId);
    this.play_state = PLAY_STATE.STOP;
    this.players.forEach(player => {
      player.reset()
    })
  }

  _update(timestamp) {
    for(let player of this._players) {
      if(player.play_state != PLAY_STATE.PLAY) continue;
      player._update(timestamp)
    }
    this.frameId = cesiumRequestAnimationFrame(this._update)
  }
}