<template>
  <div>
    {{attr}}: <span v-if="$store.state.SightFilters.filters.some(f => f.attr == `${parent}.${attr}` && f.value == value)">
      {{value}}
    </span>
    <c-button v-else variant-color="white" variant="link" size="sm" @click="onSightSelected(`${parent}.${attr}`, value)">
      {{value}}
    </c-button>
  </div>
</template>
<script>
import {CButton} from '@chakra-ui/vue'
export default {
  props: ['attr', 'value', 'parent'],
  components: {
    CButton
  },
  methods: {
    onSightSelected(attr, value) {
      this.$store.dispatch('addSightFilter', {attr, value})
    }
  }
}
</script>