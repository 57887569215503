<template>
  <CBox>
    <CHeading size="sm" mb="2">Models</CHeading>
    <CFlex direction="row" justify-content="space-between" v-for="(model, i) in models" :key="i">
      <CButton variant="ghost" variant-color="white" @click="$emit('focus', i)">{{ model }}</CButton>
      <CButton @click="$emit('remove', i)" size="sm" variant-color="vue">Delete</CButton>
    </CFlex>
  </CBox>
</template>
<script>
import {
  CBox,
  CButton,
  CHeading,
  CFlex,
} from '@chakra-ui/vue';
// import { ApiRoutes } from '../api/api_routes';

export default {
  inject: ['cesiumViewer'],
  props: ['models'],
  components: {
    CBox,
    CButton,
    CHeading,
    CFlex,
  },
}
</script>