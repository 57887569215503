export function fixEntityData(descR) {
  return descR
    .replace(/\\/g,'')
    .replace(/null/g,'')
    .replace('{','<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp')
    .replace('}','</p>')
    .replace(/"/g,'')
    .replace(/,/g,'</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp')
    .replace(/:/g,' : ')
    .replace(/gdm/g,'')
}

export const imageUrlToBase64 = (url) => new Promise((resolve, reject) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.src = url;
  img.onload = () => {
    const canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d');
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);
    resolve(canvas.toDataURL('image/jpeg'));
  };
  img.onerror = error => reject(error);
})

export function formatSightAttr (value) {
  return value.replace('gdm', '').replace( /((?<![.| ])[A-Z])/g, " $1" ).trim()
}

export class ColorGen {
  constructor(incStep = 25) {
    this.r = 0;
    this.g = 0;
    this.b = 0;
    this.incStep = incStep
  }
  next() {
    if(this.b+this.incStep >= 255 ) {
      this.incG()
    }else{
      this.b += this.incStep
    }
  }
  nextColor() {
    let c = `rgb(${this.r}, ${this.g}, ${this.b})`
    this.next()
    return c
  }
  incG() {
    if(this.g+this.incStep >= 255) {
      this.incR()
    }else{
      this.g += this.incStep
      this.b = 0
    }
  }
  incR() {
    if(this.r+this.incStep >= 255) {
      return;
    }else{
      this.r += this.incStep
      this.g = 0;
      this.b = 0;
    }
  }
}

export const parseCookie = str =>
  str
  .split(';')
  .map(v => v.split('='))
  .reduce((acc, v) => {
    acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
    return acc;
  }, {});