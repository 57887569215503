<template>
  <div>
    <div v-if="Array.isArray(item)"> 
      <div v-for="(data, i) in item" :key="i">
        <template v-if="typeof data == 'object'">
          <ReferenceSightObject :item="data" :skips="skips" />
        </template>
        <template v-else>
          <span>{{ i !=0 ? ', ' : ''}}{{ data }}</span>
        </template>
      </div>
    </div>
    <div v-else v-for="(attr, i) in Object.keys(item).filter(k => skips.indexOf(k) < 0)" :key="i">
      <template v-if="typeof item[attr] == 'object'">
        <template v-if="attr.includes('Photo')">
          <ReferenceSightAttribute :attr="attr" :value="item[attr].photoname" />
        </template>
        <template v-else>
          <c-heading as="h6" size="xs" mt="1">{{attr | camelToWords}}</c-heading>
          <ReferenceSightObject :item="item[attr]" :skips="skips" />
        </template>
      </template>
      <template v-else>
        <ReferenceSightAttribute :attr="attr" :value="item[attr]" />
      </template>
    </div>
  </div>
</template>
<script>
import {CHeading} from '@chakra-ui/vue'
import ReferenceSightAttribute from './ReferenceSightAttribute'
import ReferenceSightObject from './ReferenceSightObject'
export default {
  name: 'ReferenceSightObject',
  props: {
    item: [Object, Array],
    skips: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    CHeading,
    ReferenceSightObject,
    ReferenceSightAttribute
  },
  filters:{
    camelToWords (value) {
      let res = value.replace( /([A-Z])/g, " $1" )
      return res.charAt(0).toUpperCase() + res.slice(1);
    }
  }
}
</script>