<template>
  <CBox
    d="flex"
    w="100vw"
    h="100vh"
    flex-dir="column"
    justify-content="center"
    id="page-signin"
  >
    <CFlex justify="center" direction="column" align="center">
      <CBox border-width="1px" p="5" boxShadow="md" class="container-signin">
        <CHeading mb="5" size="md">Panther GDM+ System</CHeading>
        <form @submit.prevent="signin">
          <CFormControl :isRequired="true" :isInvalid="!!errors.email">
            <CFormLabel for="email">Email</CFormLabel>
            <CInput type="email" id="email" v-model="form.email" />
            <CFormErrorMessage>{{errors.email}}</CFormErrorMessage>
          </CFormControl>
          <CFormControl :isRequired="true" :isInvalid="!!errors.password">
            <CFormLabel for="password">Password</CFormLabel>
            <CInput type="password" id="password" :isRequired="true" v-model="form.password" />
            <CFormErrorMessage v-if="errors.password">{{errors.password}}</CFormErrorMessage>
          </CFormControl>
          <CButton
            type="submit"
            mt="5"
            variant="outline"
            color="blue.800"
            border-color="blue.800"
            border-radius="0"
            border-width="2px"
            :_hover="{bg:'blue.800', color: '#fff'}"
            :_active="{bg:'blue.800', color: '#fff'}"
            :isLoading="busy"
          >Sign In</CButton>
        </form>
      </CBox>
    </CFlex>
    <div class="container-footer">
      <img src="/panthera-logo.png" class="footer-logo">
      <img src="/panthera-logo-text.png" class="footer-logo">
    </div>
  </CBox>
</template>

<script>
import {CBox, CHeading, CButton, CFlex, CFormErrorMessage, CFormControl, CInput, CFormLabel} from '@chakra-ui/vue'
import {ApiRoutes} from '../api/api_routes';

export default {
  components: {
    CBox,
    CHeading,
    CButton,
    CFlex,
    CFormControl,
    CFormErrorMessage,
    CInput,
    CFormLabel
  },
  data () {
    return {
      busy: false,
      form: {
        email: '',
        password: ''
      },
      errors: {
      }
    }
  },
  methods: {
    async signin() {
      if(this.validate()) {
        try {
          this.busy = true
          const res = await fetch(ApiRoutes.signin, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: this.form.email,
              password: this.form.password
            })
          })
          const data = await res.json()
          if(res.status >= 300) {
            throw new Error(data.message)
          }

          localStorage.setItem('token', data.token)
          this.$router.replace('/')
        }catch(e) {
          console.log(e)
          if(e.message)
            this.errors.password = e.message
        }finally {
          this.busy = false
        }
      }
    },
    validate() {
      this.errors = {}

      let hasError = false
      if(!this.form.email) {
        this.errors.email = "Email is required"
        hasError = true
      }
      if(!this.form.password) {
        this.errors.password = "Password is required"
        hasError = true
      }

      if(hasError) return false
      return true
    }
  }
}
</script>

<style scoped lang="scss">
#page-signin {
  background: url('/panthera-bg.jpg') no-repeat center;
  background-size: cover;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at center, rgba(0,0,0, 0) 0,  rgba(0,0,0, 0.5) 100%);
    z-index: -1;
  }
}
.container-signin {
  background: #fff;
}
.container-footer {
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 1;
}
.footer-logo {
  max-width: 25rem;
  position: absolute;
  bottom: 0;
}
</style>