<template>
  <div>
    <div v-for="(attr, i) in Object.keys(item).filter(k => skips.indexOf(k) < 0)" :key="i">
      <template v-if="typeof item[attr] == 'object'">
        <c-heading as="h6" size="xs" mt="1">{{attr | camelToWords}}</c-heading>
        <entity-object :item="item[attr]" :parent="`${parent}.${attr}`" :skips="skips" />
      </template>
      <template v-else>
        <entity-attribute :attr="attr" :value="item[attr]" :parent="parent" />
      </template>
    </div>
  </div>
</template>
<script>
import {CHeading} from '@chakra-ui/vue'
import EntityAttribute from './EntityAttribute'
import EntityObject from './EntityObject'
export default {
  name: 'entity-object',
  props: {
    item: Object,
    parent: String,
    skips: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    CHeading,
    EntityAttribute,
    EntityObject
  },
  filters:{
    camelToWords (value) {
      let res = value.replace( /([A-Z])/g, " $1" )
      return res.charAt(0).toUpperCase() + res.slice(1);
    }
  }
}
</script>