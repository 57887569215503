import Vue from 'vue'
import Router from 'vue-router'

import Signin from '../pages/signin.vue'
import Index from '../pages/index.vue'

Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
      meta: {
        requiresAuth: true,
        title: 'Home'
      }
    },
    {
      path: '/signin',
      name: 'signin',
      component: Signin,
      meta: {
        guest: true,
        title: 'Signin'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('token')) {
      next({
        path: '/signin',
        params: { nextUrl: to.fullPath }
      })
    } else {
      /*let user = JSON.parse(localStorage.getItem('user'))
      if(to.matched.some(record => record.meta.is_admin)) {
        if(user.is_admin == 1){
          next()
        }
        else{
          next({ name: 'userboard'})
        }
      }else {
        next()
      }*/
      next()
    }
  } else if(to.matched.some(record => record.meta.guest)) {
    if(!localStorage.getItem('token')){
      next()
    }
    else{
      next({ name: 'index'})
    }
  }else {
    next()
  }
})

const DEFAULT_TITLE = 'Panthera'
router.afterEach((to, _) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
      document.title = to.meta.title ? `${to.meta.title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;
  });
});

export default router