<template>
  <div>
    <div>References</div>
    <div>
      <div v-if="isLoading">Loading...</div>
      <c-stack :spacing="2">
        <CButton
          v-for="reference in references"
          @click="onReferenceSelect(reference)"
          variant-color="vue"
          size="sm"
          :key="reference.id"
        >{{ reference.title }}</CButton>
      </c-stack>
    </div>
  </div>
</template>
<script>
import {
  CButton,
  CStack,
} from '@chakra-ui/vue';
import { ApiRoutes } from '../api/api_routes';

export default {
  components: {
    CButton,
    CStack
  },
  data() {
    return {
      references: [],
      displayWindow: false,
      isLoading: false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.isLoading = true;
        const res = await this.$authFetch(ApiRoutes.references)
        this.references = await res.json()
      }catch(e) {
        console.log(e)
      }finally{
        this.isLoading = false;
      }
    },
    onReferenceSelect(reference) {
      this.$emit('onReferenceSelect', reference)
    }
  }
}
</script>