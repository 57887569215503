<template>
  <div>
    <div id="infobox">
      <span class="close-btn">
        <c-close-button size="sm" @click="$emit('close')"/>
      </span>
      <div v-html="description" />
    </div>
  </div>
</template>

<script>
import {CCloseButton} from '@chakra-ui/vue'
export default {
  props: {
    description: String
  },
  components: {
    CCloseButton
  }
}
</script>

<style scoped>
#infobox {
  position: absolute;
  top: 150px;
  right: 5px;
  width: 350px;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  background: rgba(43, 140, 91, 0.8);
  padding: 5px 8px;
  border-radius: 5px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}
.sighting {
  margin-left: 1rem;
}
.close-btn {
  position: absolute;
  right: 0;
  top: 0;
}
</style>