const state = () => {
  return {
    anySights: [],
    filters: []
  }
}

const mutations = {
  addSightFilter(state, {attr, value}) {
    state.filters.push({
      attr,
      value,
      isActive: true
    })
  },
  addAnySightFilter(state, value) {
    state.anySights.push({
      value,
      isActive: true
    })
  },
  removeSightFilter(state, index) {
    state.filters.splice(index, 1)
  },
  removeAnySightFilter(state, index) {
    state.anySights.splice(index, 1)
  },
  toggleAnySightFilter(state, index) {
    state.anySights[index].isActive = !state.anySights[index].isActive
  },
  toggleActiveSightFilter(state, index) {
    state.filters[index].isActive = !state.filters[index].isActive
  }
}

const actions = {
  addSightFilter({commit}, {attr, value}) {
    commit('addSightFilter', {attr, value})
  },
  toggleActiveSightFilter({commit}, index) {
    commit('toggleActiveSightFilter', index)
  },
  removeSightFilter({commit}, index) {
    commit('removeSightFilter', index)
  },
  addAnySightFilter({commit}, value) {
    commit('addAnySightFilter', value)
  },
  toggleAnySightFilter({commit}, index) {
    commit('toggleAnySightFilter', index)
  },
  removeAnySightFilter({commit}, index) {
    commit('removeAnySightFilter', index)
  }
}

export default {
  state,
  mutations,
  actions
}