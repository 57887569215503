<template>
  <CBox border-left="1px" border-color="#eee" pl="2">
    <c-heading size="sm" mb="3">3d Model Upload</c-heading>
    <CInput
      type="file"
      accept=".gltf"
      @change="(e) => {asset3d = e.target.files}"
      color="#2d2d2d"
      height="none"
      line-height="none"
    />
    <c-box mt="1">
      <c-heading size="sm">Destination</c-heading>
      <c-stack is-inline>
        <c-form-control>
          <c-form-label for="de-lon">Longitude (deg)</c-form-label>
          <c-input id="de-lon" v-model="form.lon" size="sm" color="#000" />
        </c-form-control>
        <c-form-control>
          <c-form-label for="de-lat">Latitude (deg)</c-form-label>
          <c-input id="de-lat" v-model="form.lat" size="sm" color="#000" />
        </c-form-control>
        <c-form-control>
          <c-form-label for="de-height">Height (meter)</c-form-label>
          <c-input id="de-height" v-model="form.height" size="sm" color="#000" />
        </c-form-control>
      </c-stack>
    </c-box>
    <c-box>
      <c-stack is-inline>
        <c-form-control>
          <c-form-label for="h-heading">Heading (deg)</c-form-label>
          <c-input
            id="h-heading"
            v-model="form.heading"
            size="sm"
            color="#000"
          />
          <c-form-helper-text color="#dfdfdf">
            Relative to EAST
          </c-form-helper-text>
        </c-form-control>
        <c-form-control>
          <c-form-label for="h-pitch">Pitch (deg)</c-form-label>
          <c-input
            id="h-pitch"
            v-model="form.pitch"
            size="sm"
            color="#000"
          />
          <c-form-helper-text color="#dfdfdf">
            Relative to NORTH
          </c-form-helper-text>
        </c-form-control>
        <c-form-control>
          <c-form-label for="h-roll">Roll (deg)</c-form-label>
          <c-input
            id="h-range"
            v-model="form.range"
            size="sm"
            color="#000"
          />
        </c-form-control>
      </c-stack>
    </c-box>
    <CButton :is-loading="isUploading" @click="upload3dModel" variant-color="vue" size="sm"
      mt="2">Upload</CButton>
  </CBox>
</template>
<script>
import { CBox, CHeading, CInput, CButton, CStack,
  CFormControl,
  CFormLabel,
  CFormHelperText
} from '@chakra-ui/vue'
import { ApiRoutes } from '../api/api_routes';
export default {
  components: {
    CBox,
    CButton,
    CHeading,
    CInput,
    CStack,
    CFormControl,
    CFormLabel,
    CFormHelperText
  },
  data() {
    return {
      isUploading: false,
      asset3d: null,
      form: {
        lon: 0,
        lat: 0,
        height: 0,
        heading: 0.0,
        pitch: 0,
        roll: 0,
      }
    }
  },
  methods: {
    async upload3dModel() {
      if (!this.asset3d) return;
      this.isUploading = true;
      const formData = new FormData();
      formData.append('model', this.asset3d[0]);
      Object.keys(this.form).forEach(fk => {
        formData.append(fk, this.form[fk]);
      })
      await this.$authFetch(ApiRoutes.asset3d, {
        method: 'POST',
        body: formData
      })
      this.isUploading = false;
      this.$emit('asset-added');
    }
  }
}
</script>