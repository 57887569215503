<template>
  <CBox
    ref="popwin"
    class="popwindow-container"
  >
    <slot/>
    <c-close-button
      @click="(e) => $emit('close', e)"
      size="sm"
      position="absolute"
      top="0"
      right="0"
      borderTopRightRadius="0"
    />
  </CBox>
</template>
<script>
import {
  CBox,
  CCloseButton
} from '@chakra-ui/vue'
export default {
  components: {
    CBox,
    CCloseButton
  },
  mounted() {
    document.addEventListener('click', this.outsideClickListener)
  },
  methods: {
    outsideClickListener(e) {
      if (e.target.closest('.popwindow-container') === null) {
        this.$emit('close', e)
      }
    }
  },
  destroyed() {
    document.removeEventListener('click', this.outsideClickListener)
  }
}
</script>

<style lang="scss" scoped>
.popwindow-container {
  position: absolute;
  top: 43px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 477px;
  height: 35rem;
  margin: 0 auto;
  background: rgba(43, 140, 91, 0.8);
  border-radius: 0;
  box-shadow: 0 0 5px rgba(0,0,0, 0.7);
  color: #fff;
  pointer-events: all;
}
</style>