import Vue from 'vue'
import Vuex from 'vuex'
import SightFilters from './sight-filters'
import alienplants from '../json-data/alien-plants.json'
import incidents from '../json-data/incidents.json'
import infrastructs from '../json-data/infrastructs.json'
import patrols from '../json-data/patrols.json'
import speciesmorts from '../json-data/species-morts.json'
import species from '../json-data/species.json'
import { PLAY_STATE } from '../utils/cesium_model_animation_player'

Vue.use(Vuex)

const dataMap = {}

dataMap['species'] = species
dataMap['speciesmorts'] = speciesmorts
dataMap['patrols'] = patrols
dataMap['infrastructs'] = infrastructs
dataMap['incidents'] = incidents
dataMap['alienplants'] = alienplants

const state = () => {
  return {
    groups: [
      {
        title: 'Ecology',
        items: [
          {
            title: 'Species',
            key: 'species',
            items: dataMap['species']
          },
          {
            title: 'Mortals',
            key: 'speciesmorts',
            items: dataMap['speciesmorts']
          }
        ]
      },
      {
        title: 'Security',
        items: [{
          title: 'Patrols',
          key: 'patrols',
          items: dataMap['patrols']
        }, {
          title: 'Incidents',
          key: 'incidents',
          items: dataMap['incidents']
        }]
      },
      {
        title: 'Management',
        items: [{
          title: 'Infrastructures',
          key: 'infrastructs',
          items: dataMap['infrastructs']
        }]
      },
      {
        title: 'Base Map Layers',
        items: [{
          title: '',
          key: 'alienplants',
          items: dataMap['alienplants']
        }]
      }
    ],
    selects: {
      species: dataMap['species'].map(i => i.search_char),
      speciesmorts: dataMap['speciesmorts'].map(i => i.search_char),
      patrols: dataMap['patrols'].map(i => i.search_char),
      incidents: dataMap['incidents'].map(i => i.search_char),
      infrastructs: dataMap['infrastructs'].map(i => i.search_char),
      alienplants: dataMap['alienplants'].map(i => i.search_char)
    },
    tDAnimationPlayingState: PLAY_STATE.PLAY
  }
}

const mutations = {
  updateChecked(state, {key, value, check}) {
    if(check) {
      state.selects[key].push(value)
    }else{
      state.selects[key].splice(state.selects[key].indexOf(value), 1)
    }
  },
  toggleAllChecked(state, {key, check}) {
    if(check) {
      state.selects[key] = dataMap[key].map(g => g.search_char)
    }else{
      state.selects[key] = []
    }
  },
  updateTDAnimationPlayState(state, pState) {
    state.tDAnimationPlayingState = pState
  }
}

const actions = {
  updateChecked({ commit }, {key, value, check}) {
    commit('updateChecked', {key, value, check})
  },
  toggleAllChecked({ commit }, {key, check}) {
    commit('toggleAllChecked', {key, check})
  },
  updateTDAnimationPlayState({ commit }, pState) {
    commit('updateTDAnimationPlayState', pState)
  }
}

const getters = {
  getDefaults: (_) => (key) => {
    return dataMap[key]
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    SightFilters
  }
})