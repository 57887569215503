<template>
  <div class="container-file-input">
    <input
      type="file"
      :id="refId"
      :accept="accept"
      :value="file"
      @input="onFileChange"
      class="file-input"/>
    <label :for="refId">{{filename}}</label>
    <img v-if="previewUrl" :src="previewUrl" alt="image-preview" />
  </div>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: File || String,
    accept: String,
    refId: String
  },
  data() {
    return {
      file: '',
      previewUrl: ''
    }
  },
  watch: {
    value(v) {
      if(!v) this.previewUrl = ''
    }
  },
  computed: {
    filename() {
      if(this.value instanceof File) return this.value.name
      else return 'Choose a file'
    }
  },
  methods: {
    onFileChange(e) {
      this.$emit('change', e.target.files[0])
      const file = e.target.files[0]
      if(file && file.type.includes('image'))
        this.previewUrl = URL.createObjectURL(file)
      else
        this.previewUrl = ""
    }
  }
}
</script>
<style scoped>
.container-file-input{
  position: relative;
}
.container-file-input label {
  padding: 0.25rem;
  border: 1px solid rgb(145, 145, 145);
  border-radius: 3px;
  display: block;
}
.file-input {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>