import Vue from 'vue'
import App from './App.vue'
import Chakra, { CThemeProvider, CReset } from '@chakra-ui/vue'
import Moment from 'vue-moment'
import Photoswipe from 'vue-pswipe'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Vuelidate from 'vuelidate'
import vuescroll from 'vuescroll/dist/vuescroll-native';
import router from './router'
import store from './store'
import VueCesium from './plugins/vue-cesium'
import AuthFetch from './plugins/vue-auth-fetch';
import "cesium/Widgets/widgets.css";
import 'swiper/css/swiper.css'
import './assets/styles/main.css';
import customTheme from './plugins/custom-theme';

Vue.config.productionTip = false

// Step 1: Each icon should be stored as an object of `path` and `viewBox`
const customIcons = {
  signout: {
    path: `<path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="m361.5 392v40c0 44.113281-35.886719 80-80 80h-201c-44.113281 0-80-35.886719-80-80v-352c0-44.113281 35.886719-80 80-80h201c44.113281 0 80 35.886719 80 80v40c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20v-40c0-22.054688-17.945312-40-40-40h-201c-22.054688 0-40 17.945312-40 40v352c0 22.054688 17.945312 40 40 40h201c22.054688 0 40-17.945312 40-40v-40c0-11.046875 8.953125-20 20-20s20 8.953125 20 20zm136.355469-170.355469-44.785157-44.785156c-7.8125-7.8125-20.476562-7.8125-28.285156 0-7.8125 7.808594-7.8125 20.472656 0 28.28125l31.855469 31.859375h-240.140625c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h240.140625l-31.855469 31.859375c-7.8125 7.808594-7.8125 20.472656 0 28.28125 3.90625 3.90625 9.023438 5.859375 14.140625 5.859375 5.121094 0 10.238281-1.953125 14.144531-5.859375l44.785157-44.785156c19.496093-19.496094 19.496093-51.214844 0-70.710938zm0 0"/>`,
    // If the icon's viewBox is `0 0 24 24`, you can ignore `viewBox`
    viewBox: "0 0 511 512",
  },
  gear: {
    path: `<path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="M416.8,269.44l-45.013-35.307c0.853-6.827,1.493-13.76,1.493-20.8s-0.64-13.973-1.493-20.8l45.12-35.307    c4.053-3.2,5.227-8.96,2.56-13.653L376.8,69.653c-2.667-4.587-8.213-6.507-13.013-4.587l-53.12,21.44    c-10.987-8.427-23.04-15.573-36.053-21.013l-8-56.533C265.653,3.947,261.28,0,255.947,0h-85.333c-5.333,0-9.707,3.947-10.56,8.96    l-8,56.533c-13.013,5.44-25.067,12.48-36.053,21.013l-53.12-21.44c-4.8-1.813-10.347,0-13.013,4.587L7.2,143.573    c-2.667,4.587-1.493,10.347,2.56,13.653l45.013,35.307c-0.853,6.827-1.493,13.76-1.493,20.8s0.64,13.973,1.493,20.8L9.76,269.44    c-4.053,3.2-5.227,8.96-2.56,13.653l42.667,73.92c2.667,4.587,8.213,6.507,13.013,4.587L116,340.16    c10.987,8.427,23.04,15.573,36.053,21.013l8,56.533c0.853,5.013,5.227,8.96,10.56,8.96h85.333c5.333,0,9.707-3.947,10.56-8.96    l8-56.533c13.013-5.44,25.067-12.48,36.053-21.013l53.12,21.44c4.8,1.813,10.347,0,13.013-4.587l42.667-73.92    C422.027,278.507,420.853,272.747,416.8,269.44z M213.28,288c-41.28,0-74.667-33.387-74.667-74.667S172,138.667,213.28,138.667    s74.667,33.387,74.667,74.667S254.56,288,213.28,288z"/>`,
    viewBox: "0 0 426.667 426.667",
  },
  layers: {
    path: `
      <path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="M490.467,134.542l-211-127.844c-14.473-8.848-32.461-8.848-46.934,0l-211,127.844C8.057,142.775,0,157.13,0,172.921    c-0.015,15.806,8.042,30.176,21.533,38.423l211.015,129.844c14.503,8.837,32.416,8.837,46.919,0l211-129.844    c13.491-8.247,21.548-22.617,21.533-38.423C512,157.13,503.943,142.775,490.467,134.542z"/>
      <path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="M504.207,238.099l-209.096,128.68c-24.302,14.823-54.49,14.485-78.179,0.029L7.786,238.109    C2.935,245.36,0,243.817,0,252.921c-0.015,15.806,8.042,30.176,21.533,38.423l211.015,129.844c14.503,8.837,32.416,8.837,46.919,0    l211-129.844c13.491-8.247,21.548-12.617,21.533-28.423C512,253.812,509.063,245.354,504.207,238.099z"/>
      <path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="M504.207,318.099l-209.096,128.68c-24.074,14.689-54.094,14.701-78.179,0.029L7.793,318.1    C2.937,325.354,0,327.935,0,337.045c-0.015,15.806,8.042,30.176,21.533,38.423l211.015,129.843    c14.503,8.837,32.416,8.837,46.919,0l211-129.844c13.491-8.247,21.548-22.617,21.533-38.423    C512,327.935,509.063,325.353,504.207,318.099z"/>
    `,
    viewBox: "0 0 512 512"
  },
  capture: {
    path: `
      <path fill="currentColor" d="m1.001 7.5c.552 0 1-.447 1-1v-1.5c0-.552.449-1 1-1h1.5c.552 0 1-.447 1-1s-.448-1-1-1h-1.5c-1.654 0-3 1.346-3 3v1.5c0 .553.448 1 1 1z"/>
      <path fill="currentColor" d="m21.001 2h-1.5c-.552 0-1 .447-1 1s.448 1 1 1h1.5c.551 0 1 .448 1 1v1.5c0 .553.448 1 1 1s1-.447 1-1v-1.5c0-1.654-1.346-3-3-3z"/>
      <path fill="currentColor" d="m.001 19c0 1.654 1.346 3 3 3h1.5c.552 0 1-.447 1-1s-.448-1-1-1h-1.5c-.551 0-1-.448-1-1v-1.5c0-.553-.448-1-1-1s-1 .447-1 1v1.5z"/>
      <path fill="currentColor" d="m23.001 16.5c-.552 0-1 .447-1 1v1.5c0 .552-.449 1-1 1h-1.5c-.552 0-1 .447-1 1s.448 1 1 1h1.5c1.654 0 3-1.346 3-3v-1.5c0-.553-.448-1-1-1z"/>
      <path fill="currentColor" d="m12 20c.553 0 1-.447 1-1v-2.101c1.956-.399 3.5-1.943 3.899-3.899h2.101c.553 0 1-.447 1-1s-.447-1-1-1h-2.101c-.399-1.956-1.943-3.5-3.899-3.899v-2.101c0-.553-.447-1-1-1s-1 .447-1 1v2.101c-1.956.399-3.5 1.943-3.899 3.899h-2.101c-.553 0-1 .447-1 1s.447 1 1 1h2.101c.399 1.956 1.943 3.5 3.899 3.899v2.101c0 .553.447 1 1 1z"/>`,
    viewBox: "0 0 24 24"
  },
  dataSettings: {
    path: `
    <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
      <ellipse cx="10.813" cy="4.813" rx="10.813" ry="4.813"/>
      <path d="M13.317,22.489c-0.758-0.193-1.324-0.811-1.467-1.566c-0.342,0.015-0.688,0.023-1.037,0.023    C4.84,20.946,0,18.793,0,16.133c0,1.11,0,1.852,0,3.68c0,2.659,4.84,4.813,10.813,4.813c0.859,0,1.691-0.049,2.493-0.134    C12.958,23.875,12.942,23.113,13.317,22.489z"/>
      <path d="M10.813,19.626c0.338,0,0.67-0.009,1-0.022v-0.552c0-0.924,0.631-1.719,1.514-1.939    c-0.232-0.393-0.317-0.84-0.262-1.271c-0.727,0.068-1.479,0.105-2.252,0.105C4.84,15.947,0,13.794,0,11.134c0,1.11,0,1.852,0,3.68    C0.001,17.472,4.84,19.626,10.813,19.626z"/>
      <path d="M10.813,14.626c1.068,0,2.098-0.071,3.073-0.2l0.808-0.808c0.387-0.388,0.899-0.586,1.415-0.586    c0.347,0,0.695,0.09,1.008,0.273c0.228-0.871,1.018-1.492,1.934-1.492h1.5c0.027,0,0.054,0.006,0.08,0.007    c0.633-0.611,0.994-1.29,0.994-2.007c0-0.977,0-1.995,0-3.68c0,2.659-4.84,4.813-10.813,4.813S0.001,8.792,0.001,6.133    c0,1.11,0,1.852,0,3.68C0.001,12.472,4.84,14.626,10.813,14.626z"/>
    </g>
    <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
      <path d="M25.813,20.551v-1.5l-1.785-0.292c-0.105-0.422-0.271-0.819-0.49-1.185l1.04-1.482l-1.061-1.061    l-1.464,1.053c-0.365-0.221-0.765-0.39-1.19-0.495l-0.312-1.776h-1.5l-0.289,1.771c-0.426,0.104-0.828,0.27-1.194,0.489    l-1.46-1.042l-1.061,1.061l1.027,1.468c-0.223,0.368-0.392,0.769-0.498,1.198l-1.763,0.293v1.5l1.762,0.312    c0.105,0.429,0.274,0.831,0.498,1.199l-1.041,1.455l1.061,1.062l1.47-1.029c0.368,0.221,0.769,0.387,1.194,0.492l0.294,1.771h1.5    l0.315-1.776c0.423-0.106,0.823-0.276,1.188-0.497l1.481,1.039l1.06-1.062l-1.057-1.467c0.219-0.365,0.385-0.763,0.489-1.187    L25.813,20.551z M19.813,21.905c-1.154,0-2.092-0.936-2.092-2.092c0-1.154,0.938-2.092,2.092-2.092    c1.156,0,2.092,0.938,2.092,2.092C21.905,20.969,20.969,21.905,19.813,21.905z"/>
    </g>
    `,
    viewBox: '0 0 25.813 25.813'
  },
  report: {
    path:`
      <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
        <path xmlns="http://www.w3.org/2000/svg" d="m19.25 0h-14.5c-1.517 0-2.75 1.233-2.75 2.75v18.5c0 1.517 1.233 2.75 2.75 2.75h14.5c1.517 0 2.75-1.233 2.75-2.75v-18.5c0-1.517-1.233-2.75-2.75-2.75zm-8.5 13v-4.924c2.4.365 4.25 2.424 4.25 4.924 0 2.757-2.243 5-5 5-1.108 0-2.123-.374-2.953-.987l3.483-3.483c.141-.14.22-.331.22-.53zm-1.5-4.924v4.614l-3.263 3.263c-.613-.83-.987-1.845-.987-2.953 0-2.5 1.85-4.559 4.25-4.924zm9 13.174h-12.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h12.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3.25h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-2.75h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-3h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75zm0-2.75h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/>
      </g>
    `,
    viewBox: '0 0 24 24'
  },
  refresh: {
    path:`
    <g fill="currentColor">
      <path xmlns="http://www.w3.org/2000/svg" d="M433.109,23.694c-3.614-3.612-7.898-5.424-12.848-5.424c-4.948,0-9.226,1.812-12.847,5.424l-37.113,36.835    c-20.365-19.226-43.684-34.123-69.948-44.684C274.091,5.283,247.056,0.003,219.266,0.003c-52.344,0-98.022,15.843-137.042,47.536    C43.203,79.228,17.509,120.574,5.137,171.587v1.997c0,2.474,0.903,4.617,2.712,6.423c1.809,1.809,3.949,2.712,6.423,2.712h56.814    c4.189,0,7.042-2.19,8.566-6.565c7.993-19.032,13.035-30.166,15.131-33.403c13.322-21.698,31.023-38.734,53.103-51.106    c22.082-12.371,45.873-18.559,71.376-18.559c38.261,0,71.473,13.039,99.645,39.115l-39.406,39.397    c-3.607,3.617-5.421,7.902-5.421,12.851c0,4.948,1.813,9.231,5.421,12.847c3.621,3.617,7.905,5.424,12.854,5.424h127.906    c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.42-7.898,5.42-12.847V36.542C438.529,31.593,436.733,27.312,433.109,23.694z"/>
      <path xmlns="http://www.w3.org/2000/svg" d="M422.253,255.813h-54.816c-4.188,0-7.043,2.187-8.562,6.566c-7.99,19.034-13.038,30.163-15.129,33.4    c-13.326,21.693-31.028,38.735-53.102,51.106c-22.083,12.375-45.874,18.556-71.378,18.556c-18.461,0-36.259-3.423-53.387-10.273    c-17.13-6.858-32.454-16.567-45.966-29.13l39.115-39.112c3.615-3.613,5.424-7.901,5.424-12.847c0-4.948-1.809-9.236-5.424-12.847    c-3.617-3.62-7.898-5.431-12.847-5.431H18.274c-4.952,0-9.235,1.811-12.851,5.431C1.807,264.844,0,269.132,0,274.08v127.907    c0,4.945,1.807,9.232,5.424,12.847c3.619,3.61,7.902,5.428,12.851,5.428c4.948,0,9.229-1.817,12.847-5.428l36.829-36.833    c20.367,19.41,43.542,34.355,69.523,44.823c25.981,10.472,52.866,15.701,80.653,15.701c52.155,0,97.643-15.845,136.471-47.534    c38.828-31.688,64.333-73.042,76.52-124.05c0.191-0.38,0.281-1.047,0.281-1.995c0-2.478-0.907-4.612-2.715-6.427    C426.874,256.72,424.731,255.813,422.253,255.813z"/>
    </g>
    `,
    viewBox: '0 0 438.529 438.528'
  },
  search: {
    path: `<path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="M495.04,404.48L410.56,320c15.36-30.72,25.6-66.56,25.6-102.4C436.16,97.28,338.88,0,218.56,0S0.96,97.28,0.96,217.6    s97.28,217.6,217.6,217.6c35.84,0,71.68-10.24,102.4-25.6l84.48,84.48c25.6,25.6,64,25.6,89.6,0    C518.08,468.48,518.08,430.08,495.04,404.48z M218.56,384c-92.16,0-166.4-74.24-166.4-166.4S126.4,51.2,218.56,51.2    s166.4,74.24,166.4,166.4S310.72,384,218.56,384z"/>`,
    viewBox: "0 0 513.28 513.28",
  },
  expand: {
    path: `<path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="M511.894,19.228c-0.031-0.316-0.09-0.622-0.135-0.933c-0.054-0.377-0.098-0.755-0.172-1.13     c-0.071-0.358-0.169-0.705-0.258-1.056c-0.081-0.323-0.152-0.648-0.249-0.968c-0.104-0.345-0.234-0.678-0.355-1.015     c-0.115-0.319-0.22-0.641-0.35-0.956c-0.13-0.315-0.284-0.616-0.428-0.923c-0.153-0.324-0.297-0.651-0.467-0.969     c-0.158-0.294-0.337-0.574-0.508-0.86c-0.186-0.311-0.362-0.626-0.565-0.93c-0.211-0.316-0.447-0.613-0.674-0.917     c-0.19-0.253-0.366-0.513-0.568-0.76c-0.443-0.539-0.909-1.058-1.402-1.551c-0.004-0.004-0.007-0.008-0.011-0.012     c-0.004-0.004-0.008-0.006-0.011-0.01c-0.494-0.493-1.012-0.96-1.552-1.403c-0.247-0.203-0.507-0.379-0.761-0.569     c-0.303-0.227-0.6-0.462-0.916-0.673c-0.304-0.203-0.619-0.379-0.931-0.565c-0.286-0.171-0.565-0.35-0.859-0.508     c-0.318-0.17-0.644-0.314-0.969-0.467c-0.307-0.145-0.609-0.298-0.923-0.429c-0.315-0.13-0.637-0.236-0.957-0.35     c-0.337-0.121-0.669-0.25-1.013-0.354c-0.32-0.097-0.646-0.168-0.969-0.249c-0.351-0.089-0.698-0.187-1.055-0.258     c-0.375-0.074-0.753-0.119-1.13-0.173c-0.311-0.044-0.617-0.104-0.933-0.135C492.072,0.037,491.37,0,490.667,0H341.333     C329.551,0,320,9.551,320,21.333c0,11.782,9.551,21.333,21.333,21.333h97.83L283.582,198.248c-8.331,8.331-8.331,21.839,0,30.17     s21.839,8.331,30.17,0L469.333,72.837v97.83c0,11.782,9.551,21.333,21.333,21.333S512,182.449,512,170.667V21.335     C512,20.631,511.963,19.928,511.894,19.228z"/>
    <path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="M198.248,283.582L42.667,439.163v-97.83c0-11.782-9.551-21.333-21.333-21.333C9.551,320,0,329.551,0,341.333v149.333     c0,0.703,0.037,1.405,0.106,2.105c0.031,0.315,0.09,0.621,0.135,0.933c0.054,0.377,0.098,0.756,0.173,1.13     c0.071,0.358,0.169,0.704,0.258,1.055c0.081,0.324,0.152,0.649,0.249,0.969c0.104,0.344,0.233,0.677,0.354,1.013     c0.115,0.32,0.22,0.642,0.35,0.957c0.13,0.315,0.284,0.616,0.429,0.923c0.153,0.324,0.297,0.651,0.467,0.969     c0.158,0.294,0.337,0.573,0.508,0.859c0.186,0.311,0.362,0.627,0.565,0.931c0.211,0.316,0.446,0.612,0.673,0.916     c0.19,0.254,0.366,0.514,0.569,0.761c0.443,0.54,0.91,1.059,1.403,1.552c0.004,0.004,0.006,0.008,0.01,0.011     c0.004,0.004,0.008,0.007,0.012,0.011c0.493,0.492,1.012,0.959,1.551,1.402c0.247,0.203,0.507,0.379,0.76,0.568     c0.304,0.227,0.601,0.463,0.917,0.674c0.303,0.203,0.618,0.379,0.93,0.565c0.286,0.171,0.565,0.35,0.86,0.508     c0.318,0.17,0.645,0.314,0.969,0.467c0.307,0.145,0.609,0.298,0.923,0.428c0.315,0.13,0.636,0.235,0.956,0.35     c0.337,0.121,0.67,0.25,1.015,0.355c0.32,0.097,0.645,0.168,0.968,0.249c0.351,0.089,0.698,0.187,1.056,0.258     c0.375,0.074,0.753,0.118,1.13,0.172c0.311,0.044,0.618,0.104,0.933,0.135c0.7,0.069,1.402,0.106,2.104,0.106     c0,0,0.001,0,0.001,0h149.333c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333h-97.83l155.582-155.582     c8.331-8.331,8.331-21.839,0-30.17S206.58,275.251,198.248,283.582z"/>`,
    viewBox: "0 0 511 512",
  },
  shrink: {
    path: `<path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="m52.586 8.586-14.586 14.586v-5.172c0-1.104-.896-2-2-2s-2 .896-2 2v10c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2s-.896-2-2-2h-5.172l14.586-14.586c.781-.781.781-2.047 0-2.828s-2.047-.781-2.828 0z"/>
    <path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="m10 56c.512 0 1.023-.195 1.414-.586l14.586-14.586v5.172c0 1.104.896 2 2 2s2-.896 2-2v-10c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2s.896 2 2 2h5.172l-14.586 14.586c-.781.781-.781 2.047 0 2.828.391.391.902.586 1.414.586z"/>`,
    viewBox: "0 0 64 64",
  },
  backward: {
    path: `<path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="m415.101562 1.550781c-3.035156-1.8125-6.796874-1.890625-9.902343-.199219l-156.800781 85.898438v-77.199219c.011718-3.613281-1.941407-6.945312-5.097657-8.699219-3.144531-1.769531-6.992187-1.730468-10.101562.097657l-228.300781 137c-3.019532 1.820312-4.8750005 5.078125-4.898438 8.601562-.0195312 3.535157 1.847656 6.8125 4.898438 8.601563l228.402343 136.898437c3.09375 1.878907 6.96875 1.917969 10.097657.101563 3.128906-1.789063 5.070312-5.101563 5.101562-8.703125v-77.199219l156.800781 85.902344c3.109375 1.667968 6.859375 1.589844 9.898438-.203125 3.019531-1.816407 4.878906-5.074219 4.902343-8.597657v-273.699218c-.109374-3.527344-1.988281-6.765625-5-8.601563zm0 0"/>`,
    viewBox: '0 -63 420.10015 420'
  },
  forward: {
    path: `<path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="m415.101562 138.386719-228.300781-136.898438c-3.09375-1.882812-6.96875-1.917969-10.101562-.101562-3.125 1.789062-5.066407 5.101562-5.097657 8.703125v77.199218l-156.800781-86c-3.105469-1.6875-6.867187-1.613281-9.902343.199219-3.019532 1.816407-4.8750005 5.074219-4.898438 8.601563v273.796875c.0234375 3.527343 1.878906 6.785156 4.898438 8.601562 1.550781.902344 3.308593 1.386719 5.101562 1.398438 1.675781.007812 3.324219-.40625 4.800781-1.199219l156.800781-85.898438v77.199219c-.011718 3.613281 1.941407 6.945313 5.097657 8.699219 3.144531 1.769531 6.992187 1.730469 10.101562-.097656l228.398438-136.902344c3.058593-1.820312 4.921875-5.121094 4.902343-8.679688-.023437-3.554687-1.925781-6.835937-5-8.621093zm0 0"/>`,
    viewBox: '0 -63 420.10015 420'
  },
  play: {
    path: `<path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="m279.203125 158.609375-264-157.199219c-3.101563-1.84375-6.949219-1.882812-10.089844-.105468-3.140625 1.777343-5.0898435 5.097656-5.10937475 8.703124v300c.02343755 3.527344 1.88281275 6.785157 4.90234375 8.601563 3.035156 1.792969 6.789062 1.867187 9.898438.199219l264-142.800782c3.136718-1.730468 5.117187-5 5.199218-8.582031.082032-3.585937-1.746094-6.941406-4.800781-8.816406zm0 0"/>`,
    viewBox: '-18 0 320 320.00929'
  },
  stop: {
    path: `<g xmlns="http://www.w3.org/2000/svg" fill="currentColor" transform="translate(42.666667, 42.666667)">
      <path d="M213.333333,426.666667 C331.15408,426.666667 426.666667,331.15408 426.666667,213.333333 C426.666667,95.5125867 331.15408,3.55271368e-14 213.333333,3.55271368e-14 C95.5125867,3.55271368e-14 3.55271368e-14,95.5125867 3.55271368e-14,213.333333 C3.55271368e-14,331.15408 95.5125867,426.666667 213.333333,426.666667 Z M128,128 L298.666667,128 L298.666667,298.666667 L128,298.666667 L128,128 Z"></path>
    </g>`,
    viewBox: '0 0 512 512'
  },
  pause: {
    path: `<path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="m96.398438 0h-86.398438c-5.515625.015625-9.984375 4.484375-10 10v300c.015625 5.515625 4.484375 9.984375 10 10h86.398438c5.519531-.015625 9.984374-4.484375 10-10v-300c-.015626-5.515625-4.480469-9.984375-10-10zm0 0"/>
    <path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="m236.898438 0h-86.5c-5.515626.015625-9.984376 4.484375-10 10v300c.015624 5.515625 4.484374 9.984375 10 10h86.402343c5.515625-.015625 9.984375-4.484375 10-10v-300c.03125-2.644531-1-5.191406-2.859375-7.070312-1.859375-1.878907-4.398437-2.93359425-7.042968-2.929688zm0 0"/>
    `,
    viewBox: '-37 0 320 320.00002'
  },
  'file-open': {
    path: `<path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="M589.173,356.232l-104.756,198.26c-5.125,9.858-19.653,20.285-30.872,20.285l-420.096,0.077   c-8.875,0-17.384-3.518-23.655-9.794C3.523,558.783,0,550.283,0,541.405l0.068-326.209c0-18.448,14.955-33.417,33.405-33.435   l30.715-0.029v28.496H43.639c-4.022,0-7.885,1.596-10.731,4.442c-2.843,2.846-4.442,6.706-4.442,10.731l0.03,305.796   c0,8.388,6.797,15.173,15.176,15.173h21.045l99.28-200.836c5.609-11.219,16.208-20.286,27.411-20.286h243.14l0.083-80.823   c15.876,1.472,28.406,14.641,28.406,30.893v49.931H574.55C587.719,325.384,598.808,338.406,589.173,356.232z M83.558,445.272   c-0.907-99.969,0-399.884,0-399.884c0-15.132,12.306-27.429,27.423-27.429h219.614c3.518,0,6.874,1.472,9.251,4.061l71,77.141   c2.128,2.323,3.321,5.364,3.321,8.515v199.839h-23.034V124.932c0-3.159-2.565-5.725-5.728-5.725h-54.343   c-6.36,0-11.532-5.163-11.532-11.511V46.721c0-3.16-2.565-5.725-5.728-5.725H110.995c-2.423,0-4.395,1.971-4.395,4.392v374.739   l-17.626,35.66C88.975,455.781,83.649,455.391,83.558,445.272z M342.588,96.182H376.8l-34.212-37.188V96.182z M355.065,142.667   H142.813c-7.82,0-14.168,6.354-14.168,14.174c0,7.814,6.354,14.171,14.168,14.171h212.258c7.82,0,14.187-6.362,14.187-14.171   C369.245,149.027,362.88,142.667,355.065,142.667z M369.245,239.376c0-7.814-6.359-14.162-14.18-14.162H142.813   c-7.82,0-14.168,6.36-14.168,14.162c0,7.814,6.354,14.162,14.168,14.162h212.258C362.88,253.539,369.245,247.19,369.245,239.376z    M128.636,322.47c0,7.813,6.357,14.162,14.171,14.162h5.089c8.958-24.967,31.164-28.324,31.164-28.324h-36.253   C135.005,308.308,128.636,314.656,128.636,322.47z"/>`,
    viewBox: '0 0 592.813 592.814'
  },
  windows: {
    path: `<path xmlns="http://www.w3.org/2000/svg" fill="currentColor" d="m0,12.402,35.687-4.8602,0.0156,34.423-35.67,0.20313zm35.67,33.529,0.0277,34.453-35.67-4.9041-0.002-29.78zm4.3261-39.025,47.318-6.906,0,41.527-47.318,0.37565zm47.329,39.349-0.0111,41.34-47.318-6.6784-0.0663-34.739z"/>`,
    viewBox: '0 0 88 88'
  }
};


Vue.use(Photoswipe)
Vue.use(VueAwesomeSwiper)
Vue.use(Vuelidate)
Vue.use(vuescroll)
Vue.use(Chakra, {
  extendTheme: customTheme,
  icons: {
    // ...
    extend: {
      ...customIcons
    }
  }
})
Vue.use(Moment)
Vue.use(VueCesium, {
  access_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxNjhkMTUxZC00ZDY4LTQ5OWUtOWJjOC0xMWM5NjRiM2ViMmQiLCJpZCI6OTQ5Miwic2NvcGVzIjpbImFzciIsImdjIl0sImlhdCI6MTU1NDIyODY2M30.skED7el94TR5QqhELrjCJJhDHnJX12kJvI5DsvH3dRA'
})
Vue.use(AuthFetch);

new Vue({
  store,
  router,
  render: h => h(CThemeProvider, [h(CReset), h(App)]),
}).$mount('#app')
