<template>
  <CBox
    d="flex"
    w="18rem"
    h="100vh"
    flex-dir="column"
    class="drawer-content"
    :class="{open: isOpen}"
  >
    <span class="close-btn"><c-close-button @click="$emit('onClose')" /></span>
    <slot name="default"></slot>
  </CBox>
</template>
<script>
import { CBox, CCloseButton } from '@chakra-ui/vue';

export default {
  props: {
    isOpen: Boolean,
  },
  components: {
    CBox,
    CCloseButton
  }
}
</script>
<style scoped>
.drawer-content {
  position: fixed;
  right: 0;
  top: 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  transform: translateX(100%);
  transition: visibility 0.7s, opacity 0.7s, transform 0.7s;
}
.drawer-content.open {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}
.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>